import React from 'react';
import {IonContent, IonPage, IonIcon, IonButton, IonImg} from '@ionic/react';
import './SucessError.scss';
import {SuccessMessage} from "../../models/SuccessError";
import Breadcrumb from "../../components/Breadcrumb";

const successMessage: SuccessMessage = {
    title: 'Photo envoyé',
    text: <p>In enim justo, rhoncus ut, imperdiet a, vene …vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.</p>,
    element: <div><IonImg src="/assets/img/maquette/7.png" /><h2>Evenement 1</h2></div>,
    btnBackTitle: 'Retour au blog',
    btnBackLink: '/blog',
    breadcrumbTitle: 'Galerie',
    breadcrumbIcon: 'assets/icon/galerie.svg'
};

const Error: React.FC = () => {
    return (
        <IonPage id="success">
            <Breadcrumb title={successMessage.breadcrumbTitle} icon={successMessage.breadcrumbIcon}/>
            <IonContent>
                <div className="element-wrapper">{successMessage.element}</div>
                <div className="message-wrapper">
                    <h1>
                        <IonIcon className="error-icon" slot="start" src="/assets/icon/success.svg"/>
                        <span>{successMessage.title}</span>
                    </h1>
                    {successMessage.text}
                    <IonButton href={successMessage.btnBackLink} expand="block" color="primary">{successMessage.btnBackTitle}</IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Error;
