import React, { useState, useEffect } from 'react';
import './NoteList.scss';
import { Note } from "../models/Note";
import { IonButton, IonButtons, IonCard, IonIcon, IonThumbnail, IonTitle, IonToolbar } from "@ionic/react";
import { Link } from "react-router-dom";
import { getMonthName } from "../helpers/date.helper";
import { ContentState } from "../state/general/content.reducer";
import { useStateValue } from "../state/state";
import { chevronBackCircleSharp, chevronForwardCircleSharp } from 'ionicons/icons';

interface NoteListProps {
    notes: Note[]
    currentYear: number
}

const NoteList: React.FC<NoteListProps> = ({ notes, currentYear }) => {
    const [{ contentState }, dispatch]: [{ contentState: ContentState }, Function] = useStateValue();
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() || 1);

    const filter = () => {
        let filteredData = [];
        for (const agenda of notes) {
            if (agenda.sortMonth === currentMonth && agenda.sortYear === currentYear) {
                filteredData.push(agenda)
            }
        }

        function smallestToBiggest(a: Note, b: Note) {
            return a.timestamp - b.timestamp;
        }

        filteredData.sort(smallestToBiggest);

        return filteredData;
    };

    let data = filter();

    useEffect(() => {
        if (contentState?.agendaData.length) data = filter();
    }, [contentState.agendaData]);

    const prevMonth = () => {
        const numMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        setCurrentMonth(numMonth);
        filter();
    };

    const nextMonth = () => {
        const numMonth = currentMonth === 11 ? 0 : currentMonth + 1;
        setCurrentMonth(numMonth);
        filter();
    };

    return (
        <div id="note-list">
            <IonToolbar>
                <IonButtons slot="start">
                    <IonButton onClick={prevMonth}>
                        <IonIcon size='large' slot="icon-only" src={chevronBackCircleSharp} color="dark" />
                    </IonButton>
                </IonButtons>
                <IonButtons slot="primary">
                    <IonButton onClick={nextMonth}>
                        <IonIcon size='large' slot="icon-only" src={chevronForwardCircleSharp} color="dark" />
                    </IonButton>
                </IonButtons>
                <IonTitle className="ion-text-center">{getMonthName(currentMonth)}</IonTitle>
            </IonToolbar>
            {data.map((note: Note) => (
                <Link to={{ pathname: '/agenda/' + note.id }} key={note.id}>
                    <IonCard>
                        <div className={`note-row ${note.inscription ? 'active' : ''}`}>
                            <div className="note-img">
                                <IonThumbnail slot="start">
                                    <img src={note.vignette.url} alt={note.vignette.alt} />
                                </IonThumbnail>
                            </div>
                            <div className="note-txt">
                                <div className="title-date">
                                    <h2>{note.title}</h2>
                                    <span>{note.date}</span>
                                </div>
                                <p>{note.descriptionShort}</p>
                            </div>
                        </div>
                    </IonCard>
                </Link>
            ))}
        </div>
    );
};

export default NoteList;
