import { RegisterUserForm } from "../../models/RegisterUserForm"

export enum RegisterUserFormActions {
  StoreRegisterForm = 'StoreRegisterForm',
  StoreFirstname = 'StoreFirstname',
  StoreLastname = 'StoreLastname',
  StoreEmail = 'StoreEmail',
  StorePassword = 'StorePassword',
  StoreConf_password = 'StoreConf_password',
  StorePhone = 'StorePhone',
}

export const registerUserFormReducer = (state: RegisterUserForm, action: any) => {
  switch (action.type) {
    case RegisterUserFormActions.StoreFirstname:
      return {
        ...state,
        firstname: action.value
      };
    case RegisterUserFormActions.StoreLastname:
      return {
        ...state,
        lastname: action.value
      };
    case RegisterUserFormActions.StoreEmail:
      return {
        ...state,
        email: action.value
      };
    case RegisterUserFormActions.StorePassword:
      return {
        ...state,
        password: action.value
      };
    case RegisterUserFormActions.StoreConf_password:
      return {
        ...state,
        conf_password: action.value
      };
    case RegisterUserFormActions.StorePhone:
      return {
        ...state,
        phone: action.value
      };
    case RegisterUserFormActions.StoreRegisterForm:
      const {firstname, lastname, email, phone} = action.value
      return {
        ...state, 
        firstname,
        lastname, 
        email,
        phone
      };

    default:
      return state
  }
};

export const registerUserFormInitialState: RegisterUserForm = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  conf_password: '',
  phone: ''
};
