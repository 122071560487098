import React, { useEffect, useState } from 'react';
import { isPlatform } from "@ionic/react";
import { IonContent, IonPage, IonLabel, IonTextarea, IonSelectOption, IonSelect, IonProgressBar, IonImg, IonButton, IonCard, IonItem } from '@ionic/react';
import Breadcrumb from "../../components/Breadcrumb";
import { User } from "../../models/User";
import { ajoutIdee, mapUser, addIdeaParameters } from "../../services/user.service";
import { UserActions, UserState } from "../../state/user/user.reducer";
import { useStateValue } from "../../state/state";
import { getBasicToastError, getToastValue, ToastColor, ToastPosition } from "../../components/Toast";
import { getHiddenInput, toBase64 } from "../../helpers/upload.helper";
import { useId } from "react-id-generator";
import { getBase64FromGalleryOrCamera } from "../../helpers/photo.helper";
import Menu from '../../components/Menu';
import './IdeaBox.scss';

const IdeaBox: React.FC = () => {
    const [idea, setIdea] = useState('');
    const [photo, setPhoto] = useState('');
    const [problem, setProblem] = useState('');
    const [isAnonyme, setIsAnonyme] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [thematique, setThematique] = useState('');
    const [sendIdeaIsDisabled, setSendIdeaIsDisabled] = useState(false);
    const [{ userState }, dispatch]: [{ userState: UserState }, Function] = useStateValue();

    const [htmlId] = useId();

    useEffect(() => {
        const oneInputIsMissing = thematique === '' || problem === '';
        setSendIdeaIsDisabled(oneInputIsMissing)
    }, [thematique, problem]);

    useEffect(() => {
        if (!isPlatform('capacitor') && typeof(photo) == "object") {
            toBase64(photo).then((resp: any) => {
                setPhoto(resp);
            });
        }
    }, [photo]);

    const renderToast = (message: string, color: ToastColor, position: ToastPosition = "top") => {
        dispatch(getToastValue(message, color, position));
    };

    const takePicture = async () => {
        setIsLoading(true);
        const base64: string = await getBase64FromGalleryOrCamera(false, htmlId)
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
        if (!base64 && isPlatform('capacitor')) return dispatch(getBasicToastError())
        if (base64 === "cancelled") return


        setPhoto(base64);
    }

    const addIdea = () => {
        const parameters: addIdeaParameters = {
            'uid': userState.currentUser.uid,
            'photo': photo,
            'message': idea,
            'problem': problem,
            'thematique': thematique,
            'is_anonymous': false,
        };

        ajoutIdee(parameters).then((result: any) => {
            if (result && result.success) {
                if (result.user) {
                    const userInfo: User = mapUser(result.user);
                    if (userInfo && userInfo.uid) dispatch({ type: UserActions.StoreUser, value: userInfo });
                }
                setIdea('');
                setProblem('');
                setPhoto('');
                setThematique('');
                setIsAnonyme(false);
                setSendIdeaIsDisabled(false);

                renderToast('Idée transmise avec succès', 'success');
            } else {
                renderToast('Une erreur est survenue lors de la transmission de la boite à idée', 'danger');
            }
        }).catch(error => {
            console.log('error ajoutIdee', error);
        })
    };

    return (
        <IonPage id="idea-page">
            <Menu />
            <Breadcrumb title="AJOUTER UNE IDÉE" icon="/assets/icon/boite-idee.svg" />
            <IonContent>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    addIdea()
                }}>

                    <IonLabel>Thématique de l'idée</IonLabel>
                    <IonCard>
                        <IonSelect value={thematique} placeholder="Choisir une catégorie" onIonChange={e => setThematique(e.detail.value!)} cancelText="Annuler">
                            <IonSelectOption value="11">Sécurité</IonSelectOption>
                            <IonSelectOption value="12">Qualité</IonSelectOption>
                            <IonSelectOption value="4">Production</IonSelectOption>
                            <IonSelectOption value="3">Maintenance</IonSelectOption>
                            <IonSelectOption value="0">Thème spécifique</IonSelectOption>
                        </IonSelect>
                    </IonCard>

                    <IonLabel>Description du problème</IonLabel>
                    <IonCard>
                        <IonItem lines='none'>
                            <IonTextarea
                                placeholder="Écrire ici"
                                value={problem}
                                autoGrow={true}
                                autocapitalize="on"
                                rows={8}
                                cols={20}
                                onIonChange={e => setProblem(e.detail.value!)}
                            />
                        </IonItem>
                    </IonCard>

                    <IonLabel>Proposition de solution</IonLabel>
                    <IonCard>
                        <IonItem lines='none'>
                            <IonTextarea
                                placeholder="Écrire ici"
                                value={idea}
                                autoGrow={true}
                                autocapitalize="on"
                                rows={8}
                                cols={20}
                                onIonChange={e => setIdea(e.detail.value!)}
                            />
                        </IonItem>
                    </IonCard>

                    {isLoading && (
                        <div className="progress">
                            <IonProgressBar type="indeterminate" />
                            <p>Chargement ...</p>
                        </div>
                    )}

                    {!isLoading && photo && (
                        <IonImg src={photo} />
                    )}

                    {!isLoading && (
                        <IonButton className='hexis-btn' onClick={takePicture} expand="full">Ajouter une photo</IonButton>
                    )}
                    {getHiddenInput(htmlId, setPhoto)}

                    {/*<div className="checkbox-center">
                        <IonItem lines="none">
                            <IonCheckbox checked={isAnonyme} onIonChange={e => setIsAnonyme(e.detail.checked!)} />
                            <IonLabel className="remove_white_space">Cochez cette case si vous souhaitez soumettre votre idée anonymement</IonLabel>
                        </IonItem>
                    </div>*/}
                    <IonButton className='btn-send' type="submit" expand="full" disabled={sendIdeaIsDisabled}>Envoyer</IonButton>

                </form>
            </IonContent>
        </IonPage>
    );
};

export default IdeaBox;
