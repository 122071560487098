import React from 'react';
import { IonIcon, IonButton, IonToolbar } from "@ionic/react";
import { Note } from "../../models/Note";
import './NoteTools.scss';
import { ContentState } from "../../state/general/content.reducer";
import { useStateValue } from "../../state/state";
import { useHistory } from "react-router";
import { chevronBackCircleSharp, chevronForwardCircleSharp } from 'ionicons/icons';

interface NoteToolsProps {
  note?: Note;
}

const NoteTools: React.FC<NoteToolsProps> = ({ note }) => {
  const [{ contentState }]: [{ contentState: ContentState }] = useStateValue();
  const history = useHistory();
  const agendaNumber = contentState.agendaData.length - 1;

  const prevNote = () => {
    let prev;
    contentState.agendaData.forEach((value, index) => {
      if (value.id === note?.id && index !== 0) {
        return false;
      }
      prev = value.id;
    });

    if (!prev) {
      prev = contentState.agendaData[agendaNumber].id;
    }

    history.replace('/agenda/' + prev);
  };

  const nextNote = () => {
    let next;
    let stopSearch = false;
    contentState.agendaData.forEach((value, index) => {
      if (value.id === note?.id) {
        stopSearch = true;
        return true;
      }

      if (stopSearch) {
        next = value.id;
        return false;
      }
    });

    if (!next) {
      next = contentState.agendaData[0].id;
    }

    history.replace('/agenda/' + next);
  };

  return (
    <div id="node-tools">
      <IonButton onClick={prevNote}>
        <IonIcon slot="icon-only" size="large" src={chevronBackCircleSharp} color="dark" />
      </IonButton>
      <span className="note-date">{note?.date}</span>
      <IonButton onClick={nextNote}>
        <IonIcon slot="icon-only" size="large" src={chevronForwardCircleSharp} color="dark" />
      </IonButton>
    </div>
  );
};

export default NoteTools;
