import { IonButton, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { menuController } from '@ionic/core/components';
import './Breadcrumb.scss';
import { useHistory, useLocation } from 'react-router';
import { addSharp, funnelSharp } from 'ionicons/icons';

interface BreadcrumbProps {
    title?: any;
    icon?: string;
    component?: any;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ component, title }) => {
    const history = useHistory();
    const loc = useLocation()

    const menuBlogOpenClose = () => {
        menuController.enable(true, 'blogFilter');
        menuController.toggle('blogFilter');
    };

    const menuGalleryOpenClose = () => {
        menuController.enable(true, 'galleryFilter');
        menuController.toggle('galleryFilter');
    };

    return (
        <div id='breadcrumb'>
            <IonHeader className='ion-padding-bottom ion-no-border'>
                <IonToolbar>
                    <IonTitle color='primary' size="large">{title}</IonTitle>
                    {loc.pathname === "/blog" &&
                        <IonButton slot='end' fill='clear' onClick={menuBlogOpenClose}>
                            <span>Filtrer</span>
                            <IonIcon slot="end" className="icon-open filter-icon" icon={funnelSharp} />
                        </IonButton>
                    }
                    {loc.pathname === "/galerie" &&
                        <>
                            <IonButton slot='end' fill='clear' onClick={() => history.push('/ajouter-photo')}>
                                Ajouter
                                <IonIcon slot="end" icon={addSharp} />
                            </IonButton>
                            <IonButton slot='end' fill='clear' onClick={menuGalleryOpenClose}>
                                <span>Filtrer</span>
                                <IonIcon slot="end" className="icon-open filter-icon" icon={funnelSharp} />
                            </IonButton>
                        </>
                    }
                </IonToolbar>
            </IonHeader>
            {component}
        </div>
    );
};

export default Breadcrumb;
