import React, { useEffect, useState } from 'react';
import { IonButton, IonButtons, IonContent, IonDatetime, IonIcon, IonItem, IonPage, IonPopover, IonRefresher, IonRefresherContent } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core/components';
import Breadcrumb from "../../components/Breadcrumb";
import NoteList from "../../components/NoteList";
import { useStateValue } from "../../state/state";
import { ContentState } from "../../state/general/content.reducer";
import { getAgendaData } from "../../services/agenda.service";
import { UserState } from "../../state/user/user.reducer";
import Menu from '../../components/Menu';
import { funnelSharp } from 'ionicons/icons';

const Agenda: React.FC = () => {
  const [{ userState, contentState }, dispatch]: [{ contentState: ContentState, userState: UserState }, Function] = useStateValue();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear() || 2020);
  const currentMenu = contentState.menuLinkData.find(c => c.url === '/agenda');
  const max = (currentYear + 3).toString();

  const changeYear = (date: any) => {
    const year = new Date(date).getFullYear();
    setCurrentYear(year);
  };

  useEffect(() => {
    const initAgenda = async () => {
      await getAgendaData(dispatch, userState.currentUser.uid);
    };
    initAgenda();
  }, []);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getAgendaData(dispatch, userState.currentUser.uid).then(() => {
      event.detail.complete();
    });
  }



  return (
    <IonPage>
      <Menu />
      <Breadcrumb title='AGENDA' icon={currentMenu?.icon} />
      <IonContent id="agenda">
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent refreshingSpinner="circles">
          </IonRefresherContent>
        </IonRefresher>
        <IonItem lines='none' id="open-date-input">
          <IonButtons slot='end'>
            <IonButton fill='clear'>{currentYear.toString()}</IonButton>
            <IonIcon color='primary' icon={funnelSharp} />
          </IonButtons>
          <IonPopover trigger="open-date-input" showBackdrop={true} dismissOnSelect={true}>
            <IonDatetime
              presentation='year'
              value={currentYear.toString()}
              min="2018"
              max={max}
              onIonChange={e => {
                changeYear(e.detail.value!)
              }}
            />
          </IonPopover>
        </IonItem>
        <NoteList notes={contentState.agendaData} currentYear={currentYear} />
      </IonContent>
    </IonPage>
  );
};

export default Agenda;
