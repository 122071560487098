import React, { useEffect } from 'react';
import { IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import Breadcrumb from "../../components/Breadcrumb";
import NavigationPage from "../../components/NavigationPage";
import { ContentState } from "../../state/general/content.reducer";
import { useStateValue } from "../../state/state";
import { RouteComponentProps } from "react-router";
import { MenuLink } from "../../models/Menu";
import { getStaticData } from "../../services/content.service";
import { BasicParameters } from "../../services/base-http.service";
import { UserState } from "../../state/user/user.reducer";
import Menu from '../../components/Menu';


const SummaryPage: React.FC<RouteComponentProps<any>> = (props) => {
    const menuId = props.match.params && props.match.params.id;
    const [{ userState, contentState }, dispatch]: [{ userState: UserState, contentState: ContentState }, Function] = useStateValue();

    const currentMenu: MenuLink | undefined = contentState.menuLinkData.find(c => c.url === '/sommaire/' + menuId);
    const navigations: MenuLink[] | undefined = currentMenu?.children;

    useEffect(() => {
        const initSummaryPage = async () => {
            if (!contentState.menuLinkData.length) {
                const parameters: BasicParameters = {
                    uid: userState.currentUser.uid,
                };
                await getStaticData(parameters, dispatch);
            }
        };
        initSummaryPage()
    }, [menuId, contentState.menuLinkData.length, dispatch]);

    return (
        <IonPage>
            <Menu />
            <Breadcrumb title='INFORMATIONS' icon={currentMenu?.icon} />
            <IonContent>
                <IonGrid>
                    {navigations ?
                        <IonRow>
                            {navigations.map((navigation: MenuLink, index) => (
                                <NavigationPage navigationElement={navigation} key={index} />
                            ))}
                        </IonRow>
                        :
                        'Rien pour le moment'
                    }
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default SummaryPage;
