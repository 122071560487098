export const getIsoFromTimestamp = (timestamp: string | number) => {
  return new Date(+timestamp * 1000).toISOString()
};

export const getTimestampFromIso = (isoDate: string) => {
 return Math.round(new Date(isoDate).getTime()/1000);
};

export const getDateFromIso = (isoDate: string, separator: string = '/') => {
  const [year, month, day] = isoDate.substring(0, 10).split('-');
  return `${day}${separator}${month}${separator}${year}`
};

export const getDateDayMonthFromIso = (isoDate: string, separator: string = '/') => {
  const [year, month, day] = isoDate.substring(0, 10).split('-');
  return `${day}${separator}${month}`
};

export const getTimeFromIso = (isoDate: string) => {
  console.log(isoDate);
  const date = new Date(isoDate);
  let hours: any = date.getHours();
  let minutes: any = date.getMinutes();
  if (hours < 10) hours=`0${hours}`;
  if (minutes < 10) minutes=`0${minutes}`;
  return `${hours}:${minutes}`
};

export const getYearFromIsoDate = (isoDate: string) => {
  return isoDate.substring(0,4)
};

export const getMonthFromTimestamp = (timestamp: string | number) => {
  return new Date(+ timestamp * 1000).getMonth()
};

export const getYearFromTimestamp = (timestamp: string | number) => {
  return new Date(+ timestamp * 1000).getFullYear()
};

export const getCurrentYear = () => {
  return new Date().toISOString().substring(0, 4)
};

export const someYearsFromNow = (years: number) => {
  let date: any = new Date().toISOString();
  date = date.split('-');
  date[0] = +date[0]+years;
  return date.join('-')
};

export const getIsoDateFromDateAndTime = (dateStart: string, timeStart: string) => {
  const goodDate = dateStart.substring(0, 10);
  const goodTime = timeStart.substring(10);
  return goodDate + goodTime
};

export const getDayAndMonthFullText = (date: string) => {
  const month = new Date(date).toLocaleString('default', { month: 'short'});
  const day = date.substring(0, 10).split('-')[2];
  return `${day} ${month}`
};

export const getDayAndMonthYearReadable = (date: string) => {
  const dayAndMonth = getDayAndMonthFullText(date);
  const year = getYearFromIsoDate(date);

  return `${dayAndMonth} ${year}`
};

export const formatRentingPeriod = (dateStart: string, dateEnd: string, year: boolean = false) => { // Du 22 nov au 27 nov
  let date = `Du ${getDayAndMonthFullText(dateStart)} au ${getDayAndMonthFullText(dateEnd)}`;
  if (year) return `${date} ${getYearFromIsoDate(dateEnd)}`;
  return date
};

export const formatRentingPeriodFullDate = (dateStart: string, dateEnd: string) => { // Du 22/12/2020 au 27/12/2020
  return `Du ${getDateFromIso(dateStart)} au ${getDateFromIso(dateEnd)}`
};

export const getMonthName = (numMonth: number) => {
  return monthNames[numMonth];
};

const monthNames = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
];
