import {postHttp, getHttp, BasicParameters} from './base-http.service'
import {Note, RawAgenda} from "../models/Note";
import {getDateFromIso, getIsoFromTimestamp, getMonthFromTimestamp, getYearFromTimestamp} from "../helpers/date.helper";
import {ContentState, ContentStateActions} from "../state/general/content.reducer";
import {RawContent} from "./content.service";
import {User} from "../models/User";
import {mapUser} from "./user.service";
import {UserActions} from "../state/user/user.reducer";

const agendaUrl = '/agenda';

const mapAgenda = (rawAgendas: RawAgenda[] | undefined): Note[] => {
  const agenda: Note[] = [];
  if(rawAgendas) {
    for (const rawAgenda of rawAgendas) {
      try {
        const note: Note = {
          id: rawAgenda.nid,
          date: getDateFromIso(getIsoFromTimestamp(rawAgenda.field_agenda_date as string)),
          timestamp: rawAgenda.field_agenda_date as number,
          sortYear: getYearFromTimestamp(rawAgenda.field_agenda_date),
          sortMonth: getMonthFromTimestamp(rawAgenda.field_agenda_date),
          image: rawAgenda.field_agenda_images,
          vignette: rawAgenda.field_thumbnail,
          title: rawAgenda.title,
          descriptionShort: rawAgenda.summary,
          description: rawAgenda.body,
          inscription: rawAgenda.field_agenda_use_subscriptions as boolean,
          attachedPieces: rawAgenda.field_agenda_files,
          is_subscriber: rawAgenda.field_agenda_subscribers,
          links: rawAgenda.field_agenda_link
        };
        agenda.push(note)
      } catch (error) {
        console.error('Error in mapAgenda(): ', error)
      }
    }
  }
  return agenda
};

export const getAgendaData = async (dispatch: Function, user_id: any): Promise<any> => {
  try {
    return refreshAgendaDataFromApi(dispatch, user_id)
  } catch (error) {
    dispatch && dispatch({type: ContentStateActions.StoreContentIsFetching, value: false})
  }
};

const refreshAgendaDataFromApi = async (dispatch: Function, user_id: any) => {
  dispatch && dispatch({type: ContentStateActions.StoreContentIsFetching, value: true});

  const rawContent: RawContent = await getHttp<RawContent>(agendaUrl + '/get_agenda', {params: {uid: user_id}});
  const content = mapAgenda(rawContent?.agenda);

  const userInfo: User = mapUser(rawContent?.user);
  if(userInfo && userInfo.uid) dispatch({type: UserActions.StoreUser, value: userInfo});

  return storeAgendaData(content, dispatch)
};

const storeAgendaData = async (content: Note[], dispatch: Function) => {
  if (content) dispatch({type: ContentStateActions.StoreAgendaData, value: content});
  dispatch({type: ContentStateActions.StoreContentIsFetching, value: false});

  return content
};

export const subscribeEvent = (parameters: BasicParameters) => {
  return postHttp(agendaUrl + '/subscription', parameters)
};

export const unsubscribeEvent = (parameters: BasicParameters) => {
  return postHttp(agendaUrl + '/unsubscription', parameters)
};

export const updateNoteData = (note_id: any, is_subscriber: any, contentState: ContentState, dispatch: Function) => {
  // Update note
  const note = contentState.agendaData.find(c => c.id === note_id);
  if (!note) {
    return false;
  }

  // Set value
  note.is_subscriber = is_subscriber;

  // Remove & insert again
  const agenda_data = contentState.agendaData.filter(b => b.id !== note_id)
  agenda_data.push(note)
  setTimeout(() => {
    dispatch({type: ContentStateActions.StoreAgendaData, value: agenda_data})
  }, 1);
};
