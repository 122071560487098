import React, {useEffect} from 'react';
import {IonButton, IonContent, IonPage} from '@ionic/react';
import '../Pages.scss';
import Breadcrumb from "../../components/Breadcrumb";
import {Note} from "../../models/Note";
import NoteTools from "./NoteTools";
import {ContentState} from "../../state/general/content.reducer";
import {useStateValue} from "../../state/state";
import {RouteComponentProps} from "react-router";
import {UserState} from "../../state/user/user.reducer";
import {getAgendaData, subscribeEvent, unsubscribeEvent, updateNoteData} from "../../services/agenda.service";
import {getToastValue, ToastColor, ToastPosition} from "../../components/Toast";
import {BasicParameters} from "../../services/base-http.service";
import { getStaticData } from "../../services/content.service";
import Menu from '../../components/Menu';

const AgendaNote: React.FC<RouteComponentProps<any>> = (props) => {
    const noteId = props.match.params && props.match.params.id;
    const [{contentState, userState}, dispatch] : [{contentState: ContentState, userState: UserState}, Function] = useStateValue();

    const note: Note | undefined = contentState.agendaData.find(c => c.id === noteId);

    const renderToast = (message:string, color: ToastColor, position: ToastPosition = "bottom") => {
        dispatch(getToastValue(message, color, position));
    };

    useEffect(() => {
        const init = async () => {
            if(note) {
                return;
            }

            if(!contentState.menuLinkData.length) {
                const parameters: BasicParameters = {
                    uid: userState.currentUser.uid,
                };
                await getStaticData(parameters, dispatch);
            }
            await getAgendaData(dispatch, userState.currentUser.uid);

        };
        init();
    }, [dispatch, noteId, contentState.menuLinkData.length, note]);

    const subscribe = () => {
        const parameters: BasicParameters = {
            uid: userState.currentUser.uid,
            nid: noteId
        };

        subscribeEvent(parameters)
        .then((result: any) => {
            if (result && result.success) {
                updateNoteData(note?.id, true, contentState, dispatch)
                renderToast('Votre inscription a bien été prise en compte', 'success', 'top')
            } else {
                renderToast('Une erreur s\'est produite, veuillez réessayer plus tard', 'danger', 'top')
            }
        })
        .catch(error => {
            renderToast('Une erreur s\'est produite, veuillez réessayer plus tard', 'danger', 'top')
        })
    };

    const unsubscribe = () => {
        const parameters: BasicParameters = {
            uid: userState.currentUser.uid,
            nid: noteId
        };

        unsubscribeEvent(parameters)
        .then((result: any) => {
            if (result && result.success) {
                updateNoteData(note?.id, false, contentState, dispatch)
                renderToast('Votre désinscription a bien été prise en compte', 'success', 'top')
            } else {
                renderToast('Une erreur s\'est produite, veuillez réessayer plus tard', 'danger', 'top')
            }
        })
        .catch(error => {
            renderToast('Une erreur s\'est produite, veuillez réessayer plus tard', 'danger', 'top')
        })
    };

    const openFile = (url: string) => {
        window.open(url);
    };

    return (
        <IonPage>
            <Menu />
            <Breadcrumb title="EVENEMENT" icon="assets/icon/agenda.svg" component={<NoteTools note={note} />}/>
            <IonContent>
                {note?.image?.url &&
                    <div style={{backgroundImage: 'url(' + note?.image.url + ')'}} className='img-banner'/>}
                <div id="page">
                    <h1>{note?.title}</h1>
                    {note?.description ? <div dangerouslySetInnerHTML={{__html:note?.description}} /> : null}
                    {note?.inscription && !note?.is_subscriber ? <IonButton expand="block" color="primary" className="btn-width-md" onClick={subscribe}>S'inscrire</IonButton> : null}
                    {note?.inscription && note?.is_subscriber ? <IonButton expand="block" color="primary" className="btn-width-md" onClick={unsubscribe}>Se désinscrire</IonButton> : null}
                    <br />
                    {note?.links ? note.links?.map((attachedPiece: any, i) => (
                      <IonButton key={i} expand="block" color="primary" className="btn-width-md" onClick={(e) => openFile(attachedPiece.uri)}>{"yokiojoi"}</IonButton>
                    )) : null}
                    <br />
                    {note?.attachedPieces ? note.attachedPieces?.map((attachedPiece: any, i) => (
                      <IonButton key={i} expand="block" color="primary" className="btn-width-md" onClick={(e) => openFile(attachedPiece.file)}>{attachedPiece.description}</IonButton>
                    )) : null}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default AgendaNote;
