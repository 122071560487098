import React, { useEffect, useState } from 'react';
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonImg,
    IonItem,
    IonInput,
    IonPage,
    IonToolbar,
    IonIcon,
    IonCard
} from '@ionic/react';
import './Account.scss';
import { Link, RouteComponentProps } from "react-router-dom";
import { useStateValue } from "../../state/state";
import { DisplayState } from "../../state/general/display.reducer";
import { getToastValue, ToastColor, ToastPosition } from "../../components/Toast";
import { manageLoginData, manageLoginError } from "../../helpers/login.helper";
import { login } from "../../services/user.service";
import { eyeOffOutline, eyeOutline, lockClosedOutline, mailOutline } from 'ionicons/icons';

const Login: React.FC<RouteComponentProps> = ({ history }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    let [{ displayState }, dispatch]: [{ displayState: DisplayState }, Function] = useStateValue();

    useEffect(() => {
        const email = localStorage.getItem('email');
        if (email) setEmail(email);
    }, []);

    useEffect(() => {
        localStorage.setItem('email', email)
    }, [email]);

    const renderToast = (message: string, color: ToastColor, position: ToastPosition = "bottom") => {
        dispatch(getToastValue(message, color, position));
    };

    const loginUser = async (e: React.FormEvent) => {
        e.preventDefault();
        let parameters = {
            "login": email,
            "password": password
        };

        login(parameters)
            .then(result => manageLoginData(result, history, renderToast, dispatch))
            .catch(error => manageLoginError(error, renderToast))
    };

    return (
        <IonPage id="account">
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="splash" text="" color="medium" className="chevron-sm" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent scroll-y='false'>
                <IonImg src="/assets/img/logo_hexis.svg" className="logo" />

                <h1>Connectez-vous</h1>
                <p>Bienvenue sur l'application des salariés de Hexis</p>

                <IonCard className='ion-margin-bottom'>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={mailOutline} />
                        <IonInput
                            placeholder='Email'
                            type="email"
                            value={email}
                            inputmode="email"
                            spellCheck={true}
                            name="email"
                            onIonChange={(e) => setEmail(e.detail.value!)}
                        />
                    </IonItem>
                </IonCard>

                <IonCard>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={lockClosedOutline} />
                        <IonInput
                            placeholder='Mot de passe'
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            spellCheck={false}
                            name="password"
                            onIonChange={(e) => setPassword(e.detail.value!)}
                        />
                        <IonIcon color='medium' slot="end" icon={showPassword ? eyeOffOutline : eyeOutline} onClick={e => setShowPassword(!showPassword)} />
                    </IonItem>
                </IonCard>
                <IonButton className='hexis-btn' onClick={loginUser} expand="full">Connexion</IonButton>

                <Link color='medium' to="/passe-oublie">Mot de passe oublié ?</Link>
            </IonContent>
        </IonPage>
    );
};

export default Login;
