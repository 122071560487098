import React from 'react';
import { IonButton, IonIcon, IonMenu, IonContent, IonList, IonItem, IonLabel, IonHeader, IonTitle, IonToolbar, IonText } from "@ionic/react";
import { menuController } from '@ionic/core/components';
import { Category } from "../../models/Article";
import { checkboxSharp, closeSharp, refreshSharp } from "ionicons/icons";

interface BlogToolsProps {
    categories: Category[]
    choiceCategory: Function
    resetChoices: Function
    selectedCategories: number[]
}

const BlogTools: React.FC<BlogToolsProps> = ({ choiceCategory, resetChoices, categories, selectedCategories }) => {

    const menuBlogOpenClose = () => {
        menuController.enable(true, 'blogFilter');
        menuController.toggle('blogFilter');
    };

    return (
        <>
            <IonMenu id="blogFilter" menuId="blogFilter" contentId="blogFilter" type="overlay" side="end" className="sub-menu">
                <IonHeader className='ion-no-border'>
                    <IonToolbar>
                        <IonTitle></IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonHeader className='ion-no-border padd-t'>
                        <IonToolbar>
                            <IonText>FILTRER</IonText>
                            <IonButton slot='end' onClick={() => { menuBlogOpenClose() }} expand="full" fill='clear' >
                                <IonIcon size='large' slot='end' icon={closeSharp} />
                            </IonButton>
                        </IonToolbar>
                    </IonHeader>
                    <IonHeader className='ion-no-border'>
                        <IonToolbar>
                            <IonButton slot='end' onClick={() => { resetChoices() }} expand="full" fill='clear' >
                                Réinitialiser
                                <IonIcon size='large' slot='end' icon={refreshSharp} />
                            </IonButton>
                        </IonToolbar>
                    </IonHeader>
                    <IonList>
                        {categories.map((category: Category, key) => {
                            const selected: number | undefined = selectedCategories.find(value => value === category.id);
                            return (
                                <IonItem lines={key === categories.length - 1 ? 'none' : 'full'} onClick={() => choiceCategory(category.id)} className={selected ? 'select' : ''} key={category.id}>
                                    {category.icon && <img className="" slot="start" src={category.icon} />}
                                    <IonIcon slot="start" src={checkboxSharp} className={selected ? '' : 'opacity0'} />
                                    <IonLabel className="ion-text-wrap">{category.nom}</IonLabel>
                                </IonItem>
                            );
                        })}
                    </IonList>
                </IonContent>
            </IonMenu>
        </>
    );
};

export default BlogTools;
