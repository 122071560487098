import React, {useEffect} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import '../Pages.scss';
import Breadcrumb from "../../components/Breadcrumb";
import {ContentState} from "../../state/general/content.reducer";
import {useStateValue} from "../../state/state";
import {RouteComponentProps} from "react-router";
import {PageModel} from "../../models/PageModel";
import {getPageData} from "../../services/content.service";
import {BasicParameters} from "../../services/base-http.service";
import { PhotoViewer } from '@ionic-native/photo-viewer';
import Menu from '../../components/Menu';

const BasicPage: React.FC<RouteComponentProps<any>> = (props) => {
    const pageId = props.match.params && props.match.params.id;
    const [{contentState}, dispatch] : [{contentState: ContentState}, Function]  = useStateValue();
    const page: PageModel | undefined = contentState.pagesData.find(c => c.id === pageId);
    const currentMenu = contentState.menuLinkData.find(c => c.url === '/page/' + page?.id);

    const parameters: BasicParameters = {
        nid: pageId
    };

    useEffect(() => {
        const initBasicPage = async () => {
            await getPageData(parameters, dispatch);
        };
        if(!page) {
            initBasicPage();
        }
    }, [pageId]);

    const handlePhotoViewer = (target: any) => {
      if (target.src) {
        PhotoViewer.show(target.src);
      }
    }

    useEffect(() => {
      const tag  = document.getElementById('page');
      if (!tag) {
        return;
      }
      const imgs = tag.getElementsByTagName('img');
      for (var i = imgs.length - 1; i >= 0; i--) {
        imgs[i].addEventListener('click', function(e) {
          handlePhotoViewer(e?.target);
        });
      }
    }, [page?.description]);

    return (
        <IonPage>
          <Menu />
            <Breadcrumb title={page?.title.toUpperCase()} icon={currentMenu?.icon}/>
            <IonContent>
                {page?.image?.url ? <div style={{backgroundImage: 'url('+ page.image.url +')'}} className='img-banner' /> : null}
                <div id="page">
                    {page?.description ? <div dangerouslySetInnerHTML={{__html:page?.description}} /> : null}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default BasicPage;
