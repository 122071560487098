import axios, { AxiosRequestConfig } from "axios";
import { CONFIG } from "../constants";
import jwtDecode from "jwt-decode";

export type Id = string | number;

type axiosType = "get" | "post";

export let jwt = "";

export interface BasicDbObject {
  id: Id;
  value: string;
}

export interface BasicParameters {
  uid?: Id;
  nid?: Id;
}

export const toPromise = (
  data: any,
  rejectPromise?: boolean,
  timeout: number = 300
): Promise<any> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (rejectPromise) reject();
      resolve(data);
    }, timeout);
  });
};

export const storeJwt = (jwt: string) => {
  return localStorage.setItem("x-jwt", jwt);
};

export const removeJwtHeader = () => {
  jwt = "";
  return localStorage.removeItem("x-jwt");
};

export const getUidFromJwt = (jwt: string) => {
  const jwtData: any = jwtDecode(jwt);
  if (jwtData && jwtData.uid) return jwtData.uid;
  return false;
};

export const getJwtValue = () => {
  if (jwt) return jwt;
  let storedJwt = localStorage.getItem("x-jwt");
  if (!storedJwt) return false;
  return storedJwt;
};

export const getJwtHeader = () => {
  const jwt = getJwtValue();
  return jwt ? { "X-JWT": `${jwt}` } : false;
};

export const baseHttp = async <T>(
  type: axiosType,
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<T | any> => {
  const headers: any = getJwtHeader();
  config = config ? { ...config, headers } : { headers };
  try {
    let result;
    if (type === "get")
      result = await axios.get<T>(CONFIG.API_ENDPOINT + url, config);
    else result = await axios.post<T>(CONFIG.API_ENDPOINT + url, data, config);
    return result.data;
  } catch (error: any) {
    const responseError = error.response;
    console.log("baseHttp responseError", responseError);

    // if(!responseError) {
    // //     window.location.href = '/splash'
    // } else if ((responseError.status && responseError.status === 405) || (responseError.data && responseError.data.code && responseError.data.code === 401)) {
    removeJwtHeader();
    window.location.href = "/splash";
    // }
  }
};

export const postHttp = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  return baseHttp<T>("post", url, data ? data : null, config);
};

export const getHttp = async <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<T> => {
  return baseHttp<T>("get", url, null, config);
};
