import React, { useEffect, useState } from 'react';
import { IonBadge, IonIcon, IonTabBar, IonTabButton } from '@ionic/react';
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useStateValue } from '../state/state';
import { getJwtValue } from "../services/base-http.service";
import { documentTextSharp, informationCircleSharp, mailSharp, newspaperSharp, personCircleSharp } from 'ionicons/icons';

import './Maintabs.scss';


const MainTabs: React.FC<RouteComponentProps> = (props) => {
  const display = getJwtValue();

  if (display && props.location.pathname !== '/error') {
    return <DisplayTabs history={props.history} />
  }
  return null;
};

export default withRouter(MainTabs);

const DisplayTabs: React.FC<any> = () => {
  const [{ userState, contentState }] = useStateValue();
  const [messagesAmount, setMessagesAmount] = useState(0);

  useEffect(() => {
    if (userState.currentUser?.nb_messages_unread) {
      setMessagesAmount(userState.currentUser.nb_messages_unread);
    }
  }, [userState.currentUser?.nb_messages_unread]);



  return (
    // <IonContent>

    // <IonTabs>
    <IonTabBar slot="bottom">
      <IonTabButton tab="news" href='/blog'>
        <IonIcon icon={newspaperSharp} />
        {/* <IonLabel>Blog/News</IonLabel> */}
      </IonTabButton>
      <IonTabButton tab="messages" href='/messages'>
        <IonIcon icon={mailSharp} />
        {/* <IonLabel>Messages</IonLabel> */}
        {!!messagesAmount && messagesAmount !== 0 && <IonBadge>{messagesAmount > 99 ? '99+' : messagesAmount}</IonBadge>}
      </IonTabButton>
      {/* <IonTabButton>
      </IonTabButton> */}
      {/* <IonTabButton tab="declarer" href='/declarer' className='tab-state'>
        <IonIcon icon={documentTextSharp} className="icon-button" />
      </IonTabButton> */}
      <IonTabButton tab="informations" href={contentState.urlInfosPage.replace('/hexis-bo/web', '')}>
        <IonIcon icon={informationCircleSharp} />
        {/* <IonLabel>Informations</IonLabel> */}
      </IonTabButton>
      <IonTabButton tab="account" href='/mon-compte'>
        <IonIcon icon={personCircleSharp} />
        {/* <IonLabel>Mon compte</IonLabel> */}
      </IonTabButton>
    </IonTabBar>
    // </IonTabs >

    // </IonContent>
  );
};