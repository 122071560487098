import { isPlatform } from "@ionic/react";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { triggerHiddenElement } from "./upload.helper";

export const getBase64FromGalleryOrCamera = async (cameraOnly = false, htmlId: any = false) => {
    if (!isPlatform('capacitor')) {
      if (htmlId) {
        triggerHiddenElement(htmlId);
        return '';
      }

      console.log('feature not available');
      return "";
    }

    let image;
    try {
      image = await Camera.getPhoto({
        quality: 70,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
        saveToGallery: true,
        promptLabelPhoto: 'Galerie photo',
        promptLabelCancel: 'Annuler',
        promptLabelPicture: 'Prendre une photo',
      })
    } catch (error) {
      console.log(error);
      // if (error.message.includes('cancelled' || 'No image picked')) return 'cancelled'
    }
    if (image && image.dataUrl) return image.dataUrl;
    return ''
  };

  export const display = (image: string) => { // image can be Base64 or normal Url
    if (image && image.length > 500) return "data:image/jpeg;base64," + image
    return image

};
