import {postHttp, getHttp, Id} from './base-http.service'
import {getDateFromIso, getIsoFromTimestamp, getMonthFromTimestamp, getYearFromTimestamp} from "../helpers/date.helper";
import {ContentStateActions} from "../state/general/content.reducer";
import {RawContent} from "./content.service";
import {Album, Picture, RawAlbum, RawPicture} from "../models/Galerie";
import {User} from "../models/User";
import {mapUser} from "./user.service";
import {UserActions} from "../state/user/user.reducer";

const albumUrl = '/album';

export interface addPhotoParameters {
  uid: Id
  title: string
  album: number
  image: string
  is_anonymous: boolean
}

const mapPictures = (rawPictures: RawPicture[] | undefined, index: number, created : string): Picture[] | undefined => {
  const pictures: Picture[] = [];
  if(rawPictures) {
    for (const rawPicture of rawPictures) {
      try {
        const picture: Picture = {
          id: index,
          url: rawPicture.url,
          date: created,
          title: rawPicture.title,
          author: rawPicture.author
        };
        index++;

        pictures.push(picture)
      } catch (error) {
        console.error('Error in mapAlbum(): ', error)
      }
    }
    return pictures;
  }
  return undefined;
};

const mapGallery = (rawAlbums: RawAlbum[] | undefined): Album[] => {
  const albums: Album[] = [];
  if(rawAlbums) {
    let index = 1;
    for (const rawAlbum of rawAlbums) {
      try {
        let created = getDateFromIso(getIsoFromTimestamp(rawAlbum.created as string));
        const album: Album = {
          id: rawAlbum.nid,
          title: rawAlbum.title,
          pictures: mapPictures(rawAlbum?.field_photos, index, created),
          description: rawAlbum.body,
          created: created
        };
        index += rawAlbum.field_photos ? rawAlbum.field_photos.length : 0;

        albums.push(album)
      } catch (error) {
        console.error('Error in mapAlbum(): ', error)
      }
    }
  }
  return albums
};

export const getGalleryData = async (dispatch: Function): Promise<any> => {
  try {
    return refreshGalleryDataFromApi(dispatch)
  } catch (error) {
    dispatch && dispatch({type: ContentStateActions.StoreContentIsFetching, value: false})
  }
};

const refreshGalleryDataFromApi = async (dispatch: Function) => {
  dispatch && dispatch({type: ContentStateActions.StoreContentIsFetching, value: true})
  const rawContent: RawContent = await getHttp<RawContent>(albumUrl + '/get_albums');

  const userInfo: User = mapUser(rawContent?.user);
  if(userInfo && userInfo.uid) dispatch({type: UserActions.StoreUser, value: userInfo});

  const content = mapGallery(rawContent?.albums);
  return storeGalleryData(content, dispatch)
};

const storeGalleryData = async (content: Album[], dispatch: Function) => {
  if (content) {
    const album = content[0];
    content = content.slice(1);
    dispatch({type: ContentStateActions.StoreAlbumForSlider, value: album});
    dispatch({type: ContentStateActions.StoreGalleryData, value: content});
  }
  dispatch({type: ContentStateActions.StoreContentIsFetching, value: false});

  return content
};

export const ajoutPhoto = (parameters: addPhotoParameters, dispatch: Function) => {
  dispatch({type: ContentStateActions.StoreContentIsFetching, value: true});
  return postHttp(albumUrl + '/set_photo', parameters);
};
