import { UserActions } from "../state/user/user.reducer";
import { mapUser } from "../services/user.service";
import { removeJwtHeader, storeJwt } from "../services/base-http.service";

export const manageLoginData = (data: any, history: any, renderToast: Function, dispatch: Function) => {
  if (data && data['reason']) {
    renderToast(data['reason'], 'danger');
    return false;
  }

  if (data && data['user']) {
    const mappedUser = mapUser(data['user']);
    if (data['x-jwt']) storeJwt(data['x-jwt']);

    renderToast('Vous êtes connecté', 'success', 'top');
    dispatch({
      type: UserActions.StoreUser,
      value: mappedUser
    })
  }

  return history.replace({
    pathname: '/blog',
    state: {
      'showLoggedInToast': true
    }
  });
};

export const manageRegisterData = (data: any, history: any, renderToast: Function) => {
  if (data && data['reason']) {
    renderToast(data['reason'], 'danger');
    return false;
  }

  renderToast('Votre demande a été prise en compte', 'success', 'top');

  return history.replace({
    pathname: '/connexion',
    state: {
      'showLoggedInToast': true
    }
  });
};

export const manageLogoutData = (data: any, history: any, renderToast: Function, dispatch: Function) => {
  if (data && data['reason']) {
    renderToast(data['reason'], 'danger');
    return false;
  } else {
    removeJwtHeader();

    dispatch({
      type: UserActions.StoreUser,
      value: {}
    })

    setTimeout(() => {
      renderToast('Vous avez été deconnecté', 'danger', 'top');

      return history.push({
        pathname: '/splash',
      });
    }, 100);
  }
};

export const manageLoginError = (error: any, renderToast: Function) => {
  renderToast("Oups, une erreur est survenue", 'danger');
  return false;
};
