import React from 'react';
import { IonContent, IonGrid, IonPage} from '@ionic/react';
import Breadcrumb from "../../components/Breadcrumb";
import {Navigation} from "../../models/Navigation";

/* todo exemple */
const navigations: Navigation[] = [
    {
        id: 1,
        link: "/composition",
        imgSrc: "/assets/icon/composition.svg",
        imgAlt: "Composition",
        name: "Composition",
    },
    {
        id: 2,
        link: "/coordonnees",
        imgSrc: "/assets/icon/coordonnees.svg",
        imgAlt: "Coordonnées",
        name: "Coordonnées",
    },
    {
        id: 3,
        link: "/pv-reunions",
        imgSrc: "/assets/icon/pv-reunions.svg",
        imgAlt: "PV des réunions",
        name: "PV des réunions",
    }
];

const SocialCommittee: React.FC = () => {
    return (
        <IonPage>
            <Breadcrumb title="Comité Social et Eco." icon="/assets/icon/comite-social.svg"/>
            <IonContent>
                <IonGrid>
                    {/*<IonRow>*/}
                    {/*    {navigations.map((navigation: Navigation) => (*/}
                    {/*        <NavigationPage navigationElement={navigation} key={navigation.id}/>*/}
                    {/*    ))}*/}
                    {/*</IonRow>*/}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default SocialCommittee;
