import React from 'react';
import {IonContent, IonGrid, IonPage} from '@ionic/react';
import Breadcrumb from "../../components/Breadcrumb";
import {Navigation} from "../../models/Navigation";

/* todo exemple */
const navigations: Navigation[] = [
    {
        id: 1,
        imgSrc: "/assets/img/maquette/9-2x.png",
        imgAlt: "John Doe",
        name: "John Doe",
        subName: "Président",
    },
    {
        id: 2,
        imgSrc: "/assets/img/maquette/9-2x.png",
        imgAlt: "John Doe",
        name: "John Doe",
        subName: "Président",
    },
    {
        id: 3,
        imgSrc: "/assets/img/maquette/9-2x.png",
        imgAlt: "John Doe",
        name: "John Doe",
        subName: "Président",
    },
    {
        id: 4,
        imgSrc: "/assets/img/maquette/9-2x.png",
        imgAlt: "John Doe",
        name: "John Doe",
        subName: "Président",
    },
];

const Composition: React.FC = () => {
    return (
        <IonPage>
            <Breadcrumb title="Composition" icon="/assets/icon/composition.svg"/>
            <IonContent>
                <IonGrid>
                    {/*<IonRow>*/}
                    {/*    {navigations.map((navigation: Navigation) => (*/}
                    {/*        <NavigationPage navigationElement={navigation} key={navigation.id}/>*/}
                    {/*    ))}*/}
                    {/*</IonRow>*/}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Composition;
