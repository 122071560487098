import React from 'react';
import {
	IonHeader,
	IonButtons,
	IonBackButton,
	IonToolbar,
	IonButton,
	IonIcon,
	IonImg
} from '@ionic/react';

import { getJwtValue } from "../services/base-http.service";
import './Header.scss';
import { menu } from 'ionicons/icons';
import { useHistory } from "react-router-dom";


interface HeaderProps {
	menuOpenClose: Function;
	btnMenuClass: string;
	showBackButton: boolean;
}

const Header: React.FC<HeaderProps> = ({ menuOpenClose, btnMenuClass, showBackButton }) => {
	const display = getJwtValue();
	if (display) {
		return <DisplayHeader menuOpenClose={menuOpenClose} btnMenuClass={btnMenuClass} showBackButton={showBackButton} />
	}
	return null;
};

const DisplayHeader: React.FC<HeaderProps> = ({ menuOpenClose, btnMenuClass, showBackButton }) => {

	const history = useHistory();

	return (
		<>
			<IonHeader id="main-header" className="ion-no-border">
				<IonToolbar className='main-header'>
					<IonImg src="/assets/img/logo_hexis.svg" className="logo" onClick={() => history.push('/blog')} />
					{!showBackButton &&
						<IonButtons slot="start">
							<IonBackButton defaultHref="/blog" text="" color="medium" className="chevron-sm" />
						</IonButtons>
					}
					<IonButtons slot="primary">
						<IonButton color="medium" onClick={() => menuOpenClose()}>
							<IonIcon size='large' slot="icon-only" icon={menu} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
		</>
	);
};

export default Header;
