export enum DisplayStateActions {
  ShowToast = 'ShowToast'
}

export const displayReducer = (state: DisplayState, action: any) => {
  switch (action.type) {
    case DisplayStateActions.ShowToast:
        return {
        ...state,
        toastData: {...action.value}
        };

    default:
      return state;
  }
}

export interface Toast {
  open: boolean,
  message: string,
  color: string,
  position: string
  duration?: number,
}

export interface DisplayState {
  toastData: Toast
}

export const displayStateInitialState: Toast = {
  open: false,
  message: '',
  color: 'succes',
  position: 'bottom',
  duration: 3000,
}