import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonInput,IonButton, IonCard, IonIcon, IonItem, IonAlert } from '@ionic/react';
import './Account.scss';
import { RouteComponentProps } from "react-router";
import { UserActions, UserState } from "../../state/user/user.reducer";
import { useStateValue } from "../../state/state";
import { getBasicToastError, getToastValue, ToastColor, ToastPosition } from "../../components/Toast";
import { RawUser, User } from "../../models/User";
import { getUserProfile, logout, saveUserInfos, removeAccount } from "../../services/user.service";
import { manageLogoutData, manageLoginError } from "../../helpers/login.helper";
import { validateEmail } from "../../helpers/formValidation.helper";
import { callOutline, lockClosedOutline, mailOutline, personOutline } from 'ionicons/icons';
import Menu from '../../components/Menu';
import Breadcrumb from '../../components/Breadcrumb';

const Account: React.FC<RouteComponentProps<any>> = (props) => {

    const [{ userState }, dispatch]: [{ userState: UserState }, Function] = useStateValue();

    const [prenom, setPrenom] = useState(userState.currentUser?.prenom || '');
    const [nom, setNom] = useState(userState.currentUser?.nom || '');
    const [email, setEmail] = useState(userState.currentUser?.email || '');
    const [telephone, setPhone] = useState(userState.currentUser?.telephone || '');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [emailIsValid, setEmailIsValid] = useState(true);
    const [phoneIsValid, setPhoneIsValid] = useState(true);

    const [showDeleteAccountAlert, setShowDeleteAccountAlert] = useState(false);
    const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

    const renderToast = (message: string, color: ToastColor, position: ToastPosition = "top") => {
        dispatch(getToastValue(message, color, position));
    };

    useEffect(() => {
        if (!userState || !userState.currentUser || !userState.currentUser.uid) props.history.replace('/blog');

        if (emailIsValid !== validateEmail(email)) setEmailIsValid(!emailIsValid);

        const oneInputIsMissing = !prenom || !nom || !email;
        const formIsValid = !oneInputIsMissing && emailIsValid && password === confirmPassword;
        setButtonIsDisabled(!formIsValid)
    }, [dispatch, emailIsValid, prenom, nom, email, password, confirmPassword]);

    const saveUserChanges = () => {
        const userInfos: RawUser = {
            uid: userState.currentUser.uid,
            firstname: prenom,
            lastname: nom,
            email: email,
            phone: telephone,
        };

        if (password) {
            userInfos.pass = password
        }

        saveUserInfos(userInfos).then((result) => {
            getUserProfile(userState.currentUser.uid).then((userInfo: User | null) => { // Here we refresh the user info
                if (userInfo && userInfo.uid) dispatch({ type: UserActions.StoreUser, value: userInfo })
            });
            dispatch(getToastValue('Vos données ont été mises à jour avec succès', 'success', 'top'));
            props.history.goBack()
        }).catch((err) => {
            console.error(err);
            return dispatch(getBasicToastError());
        })
    };

    const logOut = () => {
        const user: RawUser = {
            uid: userState.currentUser.uid
        };

        logout(user)
            .then(result => manageLogoutData(result, props.history, renderToast, dispatch))
            .catch(error => manageLoginError(error, renderToast))
    };

    const deleteAccount = () => {

        const user: RawUser = {
            uid: userState.currentUser.uid
        };

        removeAccount(user)
            .then(result => manageLogoutData(result, props.history, renderToast, dispatch))
            .catch(error => manageLoginError(error, renderToast))
    };

    return (
        <IonPage id="account">
            <Menu />
            <Breadcrumb title={"MON COMPTE"} />
            <IonContent>
                <h1>Modification du compte</h1>
                <IonCard>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={personOutline} />
                        <IonInput type="text" value={nom} onIonChange={e => setNom(e.detail.value!)} spellCheck={false} autocapitalize="on" required placeholder="Nom" />
                    </IonItem>
                </IonCard>
                <IonCard>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={personOutline} />
                        <IonInput type="text" value={prenom} onIonChange={e => setPrenom(e.detail.value!)} spellCheck={false} autocapitalize="on" required placeholder="Prénom" />
                    </IonItem>
                </IonCard>
                <IonCard>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={mailOutline} />
                        <IonInput type="email" value={email} onIonChange={e => setEmail(e.detail.value!)} spellCheck={false} autocapitalize="off" required placeholder="Adresse e-mail" />
                    </IonItem>
                </IonCard>
                <IonCard>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={lockClosedOutline} />
                        <IonInput type="password" value={password} onIonChange={e => setPassword(e.detail.value!)} spellCheck={false} autocapitalize="off" placeholder="Nouveau mot de passe" />
                    </IonItem>
                </IonCard>
                <IonCard>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={lockClosedOutline} />
                        <IonInput type="password" value={confirmPassword} onIonChange={e => setConfirmPassword(e.detail.value!)} spellCheck={false} autocapitalize="off" placeholder="Confirmer mot de passe" />
                    </IonItem>
                </IonCard>
                <IonCard>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={callOutline} />
                        <IonInput type="tel" value={telephone} onIonChange={e => setPhone(e.detail.value!)} spellCheck={false} autocapitalize="off" placeholder="Numéro de téléphone" />
                    </IonItem>
                </IonCard>
                <IonButton className='hexis-btn' onClick={saveUserChanges} expand="full" disabled={buttonIsDisabled}>Modifier</IonButton>
                <IonButton className='hexis-btn' onClick={logOut} expand="full" fill="outline" color="primary">Se déconnecter</IonButton>
                <IonButton className='hexis-btn delete-account' onClick={() => {setShowDeleteAccountAlert(true)}} expand="full" fill="outline" color="primary">Supprimer mon compte</IonButton>
                <IonAlert
                    isOpen={showDeleteAccountAlert}
                    onDidDismiss={() => setShowDeleteAccountAlert(false)}
                    header="Attention"
                    subHeader=""
                    message="Êtes-vous sûr de vouloir supprimer votre compte ?"
                    buttons={[
                        {
                            text: 'Oui',
                            handler: deleteAccount
                        },
                        {
                            text: 'Non',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                              console.log('Confirm Cancel');
                            }
                        }
                    ]}

                  />

            </IonContent>
        </IonPage>
    );
};

export default Account;
