import {BasicParameters, getHttp, postHttp} from './base-http.service'
import {MenuLink, RawMenuLink} from '../models/Menu'
import {ContentStateActions} from "../state/general/content.reducer";
import {Category, RawArticle} from "../models/Article";
import {RawAgenda} from "../models/Note";
import {RawFlashInfo} from "../models/FlashInfo";
import {PageModel, RawPageModel} from "../models/PageModel";
import {RawUser, User} from "../models/User";
import {UserActions} from "../state/user/user.reducer";
import {mapUser} from "./user.service";
import {RawAlbum} from "../models/Galerie";

const contentUrl = '/content';

interface Content {
  pagesData?: PageModel[]
  menuLinkData?: MenuLink[]
  thematiqueData?: Category[]
}

export interface RawContent {
  user: RawUser
  page?: RawPageModel
  blog?: RawArticle[]
  menu?: RawMenuLink[]
  albums?: RawAlbum[]
  agenda?: RawAgenda[]
  messages?: RawArticle[]
  thematique?: Category[]
  flash_info?: RawFlashInfo[]
}

const mapMenuLink = (rawLinks: RawMenuLink[] | undefined, index: number = 0): MenuLink[] | undefined => {
  if(rawLinks) {
    const links: MenuLink[] = [];
    for (const rawLink of rawLinks) {
      try {
        index++;
        let link: MenuLink = {
          url: rawLink.link,
          icon: rawLink.icon || '',
          title: rawLink.name,
          children: mapMenuLink(rawLink.children, index),
          icon_menu: rawLink.menu_icon || '',
          isInfosPage: rawLink.infos_p || false,
        };

        links.push(link)
      } catch (error) {
        console.error('Error in mapMenu(): ', error)
      }
    }
    return links;
  }

  return undefined;
};

const mapPage = (rawPage: RawPageModel | undefined): PageModel => {
    try {
          const id = rawPage?.nid || 0;
          const title = rawPage?.title || '';
          const image = rawPage?.field_image || undefined;
          const description = rawPage?.body || '';

          return {id, title, image, description}
    } catch (error) {
        console.error('Error in mapPage(): ', error);
        throw error
    }
};

const mapContent = (rawContent: RawContent): Content =>  {
  const menuLinkData: MenuLink[] | undefined = mapMenuLink(rawContent?.menu);
  const thematiqueData: Category[] | undefined = rawContent?.thematique;
  return {menuLinkData, thematiqueData}
};

export const getStaticData = async (parameters: BasicParameters, dispatch: Function): Promise<any> => {
  try {
    return refreshStaticDataFromApi(parameters, dispatch)
  } catch (error) {
    dispatch && dispatch({type: ContentStateActions.StoreContentIsFetching, value: false})
  }
};

const refreshStaticDataFromApi = async (parameters: BasicParameters, dispatch: Function) => {

  dispatch && dispatch({type: ContentStateActions.StoreContentIsFetching, value: true});
  const rawContent: RawContent = await getHttp<RawContent>(contentUrl+'/get_build', {params: {uid: parameters.uid}});
  const content = mapContent(rawContent);

  const userInfo: User = mapUser(rawContent?.user);
  if(userInfo && userInfo.uid) dispatch({type: UserActions.StoreUser, value: userInfo});

  return storeStaticData(content, dispatch)
};

const storeStaticData = async (content: Content, dispatch: Function) => {
  if (content.menuLinkData) {
    const menu: MenuLink | undefined = content.menuLinkData.find(c => c.isInfosPage === true);
    dispatch({type: ContentStateActions.StoreMenuLinkData, value: content.menuLinkData});
    dispatch({type: ContentStateActions.StoreInfosPageUrl, value: menu?.url});
  }
  if (content.thematiqueData) dispatch({type: ContentStateActions.StoreThematiqueData, value: content.thematiqueData});
  dispatch({type: ContentStateActions.StoreContentIsFetching, value: false});

  return content
};

export const getPageData = async (parameters: BasicParameters, dispatch: Function): Promise<any> => {
  try {
    const rawContent: RawContent = await postHttp<RawContent>(contentUrl+'/get_page', parameters);
    const content = mapPage(rawContent?.page);

    const userInfo: User = mapUser(rawContent?.user);
    if(userInfo && userInfo.uid) dispatch({type: UserActions.StoreUser, value: userInfo});

    return storePageData(content, dispatch)
  } catch (error) {
    dispatch && dispatch({type: ContentStateActions.StoreContentIsFetching, value: false})
  }
};

const storePageData = async (content: PageModel, dispatch: Function) => {
  if (content) {
    dispatch({type: ContentStateActions.StorePagesData, value: content});
  }
  dispatch({type: ContentStateActions.StoreContentIsFetching, value: false});

  return content
};
