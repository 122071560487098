import React, { useEffect, useState } from 'react';
import {
    IonContent,
    IonPage,
    IonImg,
    IonInput,
    IonButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonCard,
    IonItem,
    IonIcon} from '@ionic/react';
import './Account.scss';

import axios from 'axios';
import { useStateValue } from "../../state/state";
import { CONFIG } from "../../constants";
import { getToastValue } from "../../components/Toast";
import { RouteComponentProps } from "react-router";
import { validateEmail } from "../../helpers/formValidation.helper";
import { mailOutline } from 'ionicons/icons';

const ForgotPassword: React.FC<RouteComponentProps> = ({ history }) => {

    const [email, setEmail] = useState('');
    const [{ displayState }, dispatch] = useStateValue()

    const [emailIsValid, setEmailIsValid] = useState(false);
    const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

    useEffect(() => {
        if (emailIsValid !== validateEmail(email)) setEmailIsValid(!emailIsValid);

        const formIsValid = emailIsValid;
        setButtonIsDisabled(!formIsValid)
    }, [email, emailIsValid]);

    const askForPassword = () => {
        let parameters = { "email": email };

        axios.post(CONFIG.API_ENDPOINT + '/user/password_lost', parameters).then(result => {
            let data = result['data'];

            if (data && data['reason']) {
                return dispatch(getToastValue(data['reason'], 'danger', 'bottom'))
            }

            dispatch(getToastValue('Un lien permettant de modifier votre mot de passe vous a été envoyé par e-mail', 'success', 'bottom'));
            history.push({ pathname: '/connexion' });

        }, (error) => {
            console.error(error);
            dispatch(getToastValue(error.toString(), 'danger', 'bottom'));
        })
    }

    return (
        <IonPage id="account">
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="connexion" text="" color="medium" className="chevron-sm" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonImg src="/assets/img/logo_hexis.svg" className="logo" />
                <h1>Mot de passe oublié</h1>
                <p>Envoie d’un nouveau<br />mot de passe sur votre email.</p>
                <IonCard>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={mailOutline} />
                        <IonInput type="email" onIonChange={e => setEmail(e.detail.value!)} placeholder="Email" />
                    </IonItem>
                </IonCard>
                <IonButton className='hexis-btn' onClick={askForPassword} expand="full" disabled={buttonIsDisabled}>Envoyer</IonButton>
            </IonContent>
        </IonPage>
    );
};

export default ForgotPassword;
