import React, { useState } from 'react';
import {
    IonContent,
    IonPage,
    IonButton,
    IonItem, IonSelect, IonLabel, IonSelectOption, IonTextarea, IonInput, IonGrid, IonRow, IonCol, IonDatetime, IonIcon, IonPopover, IonImg, IonProgressBar
} from '@ionic/react';

import './Statement.scss';
import Breadcrumb from '../../components/Breadcrumb';
import Menu from '../../components/Menu';
import { format, parseISO } from 'date-fns';
import { calendarNumberSharp } from 'ionicons/icons';
import { getBasicToastError, getToastValue } from '../../components/Toast';
import { getBase64FromGalleryOrCamera } from '../../helpers/photo.helper';
import { ContentState } from '../../state/general/content.reducer';
import { useStateValue } from '../../state/state';
import { UserState } from '../../state/user/user.reducer';

const Statement: React.FC = () => {
    const [{userState, contentState}, dispatch]: [{userState: UserState, contentState: ContentState}, Function] = useStateValue();
    const [registerButtonIsDisabled, setRegisterButtonIsDisabled] = useState(false);

    // FIELDS
    const [emitter, setEmitter] = useState('');
    const [site, setSite] = useState('');
    const [popoverDate, setPopoverDate] = useState('');
    const [service, setService] = useState('');
    const [services, setServices] = useState([]);
    const [subject, setSubject] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [workUnit, setWorkUnit] = useState('');
    const [workUnits, setWorkUnits] = useState([]);
    const [categorie, setCategorie] = useState('');
    const [categories, setCategories] = useState([]);
    const [situation, setSituation] = useState('');
    const [action1, setAction1] = useState('');
    const [action2, setAction2] = useState('');
    const [action3, setAction3] = useState('');

    // PICTURE
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [photo, setPhoto]: any = useState('');


    // useEffect(() => {
    //     const formIsValid = emitter && site && popoverDate && service && subject && workUnit && categorie && situation && action1
    //     setRegisterButtonIsDisabled(!formIsValid)
    // }, [emitter, site, popoverDate, service, subject, workUnit, categorie, situation, action1])

    const takePicture = async () => {
        setIsLoading(true);
        const base64: string = await getBase64FromGalleryOrCamera()
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
        if (!base64) return dispatch(getBasicToastError())
        if (base64 === "cancelled") return

        setPhoto(base64);
    }

    const formatDate = (value: any) => {
        return format(parseISO(value), 'dd MMM yyyy HH:mm');
    };

    const sendStatement = () => {
        if (!emitter || !site || !popoverDate || !service || !subject || !workUnit || !categorie || !situation || !action1) {
            dispatch(getToastValue("Veuillez saisir les champs obligatoires", "danger"));
            return false;
        }

        // let parameters = {

        // }
    }

    return (
        <IonPage id="statement">
            <Menu />
            <Breadcrumb title='DÉCLARER' />
            <IonContent className='ion-padding-horizontal'>
                <IonGrid>
                    <IonRow>
                        <IonCol size='12'>
                            <IonLabel>Émetteur</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    value={emitter}
                                    placeholder="Entrez l'émetteur"
                                    required
                                    onIonChange={(e) => setEmitter(e.detail.value!)}
                                />
                            </IonItem>

                            <IonLabel>Site concerné</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    value={site}
                                    placeholder='Entrez le site concerné'
                                    required
                                    onIonChange={(e) => setSite(e.detail.value!)}
                                />
                            </IonItem>


                            <IonLabel>Date</IonLabel>
                            <IonItem lines='none' id="open-date-input">
                                <IonLabel>{popoverDate}</IonLabel>
                                <IonPopover size='cover' trigger="open-date-input">
                                    <IonDatetime
                                        locale='fr-FR'
                                        firstDayOfWeek={1}
                                        onIonChange={ev => {
                                            setPopoverDate(formatDate(ev.detail.value!))
                                        }}
                                    >
                                        {/* <IonButtons slot="buttons">
                                            <IonButton></IonButton>
                                            <IonButton color="primary" slot='end' onClick={(e) => }>Fermer</IonButton>
                                        </IonButtons> */}
                                    </IonDatetime>
                                </IonPopover>
                                <IonIcon color='medium' icon={calendarNumberSharp} />
                            </IonItem>

                            <IonLabel>Service/Activité</IonLabel>
                            <IonItem lines='none'>
                                <IonLabel></IonLabel>
                                <IonSelect placeholder='Service/Activité' interface='popover' value={service} okText="Choisir" cancelText="Annuler" onIonChange={e => setService(e.detail.value)}>
                                    <IonSelectOption value="maintenance">Maintenance</IonSelectOption>
                                    <IonSelectOption value="production">Production</IonSelectOption>
                                    <IonSelectOption value="ingénierie">Ingénierie</IonSelectOption>
                                    <IonSelectOption value="logistique">Logistique</IonSelectOption>
                                </IonSelect>
                            </IonItem>

                            <IonLabel>Sujet concerné</IonLabel>
                            <IonItem lines='none'>
                                <IonLabel></IonLabel>
                                <IonSelect placeholder='Sujet concerné' interface='popover' value={subject} okText="Choisir" cancelText="Annuler" onIonChange={e => setSubject(e.detail.value)}>
                                    <IonSelectOption value="salarié">Salarié</IonSelectOption>
                                    <IonSelectOption value="intérimaire">Intérimaire</IonSelectOption>
                                    <IonSelectOption value="extérieur">Extérieur</IonSelectOption>
                                </IonSelect>
                            </IonItem>

                            <IonLabel>Unité de travail concernée</IonLabel>
                            <IonItem lines='none'>
                                <IonLabel></IonLabel>
                                <IonSelect placeholder='Unité de travail' interface='popover' value={workUnit} okText="Choisir" cancelText="Annuler" onIonChange={e => setWorkUnit(e.detail.value)}>
                                    <IonSelectOption value="salarié">Salarié</IonSelectOption>
                                    <IonSelectOption value="intérimaire">Intérimaire</IonSelectOption>
                                    <IonSelectOption value="extérieur">Extérieur</IonSelectOption>
                                </IonSelect>
                            </IonItem>

                            <IonLabel>Catégorie</IonLabel>
                            <IonItem lines='none'>
                                <IonLabel></IonLabel>
                                <IonSelect placeholder='Catégorie' interface='popover' value={categorie} okText="Choisir" cancelText="Annuler" onIonChange={e => setCategorie(e.detail.value)}>
                                    <IonSelectOption value="incident">Incident</IonSelectOption>
                                    <IonSelectOption value="situation_dangereuse">Situation dangereuse</IonSelectOption>
                                    <IonSelectOption value="autres">Autres</IonSelectOption>
                                </IonSelect>
                            </IonItem>

                            {categorie === 'autres' &&
                                <>
                                    <IonItem lines='none'>
                                        <IonInput
                                            placeholder='Entrez la catégorie'
                                            required
                                        />
                                    </IonItem>
                                </>
                            }

                            <IonLabel>Description de la situation identifiée</IonLabel>
                            <IonItem lines='none'>
                                <IonTextarea
                                    placeholder="Écrire ici"
                                    autoGrow={true}
                                    value={situation}
                                    autocapitalize="on"
                                    rows={4}
                                    cols={20}
                                    required
                                    onIonChange={(e) => setSituation(e.detail.value!)}
                                />
                            </IonItem>

                            {isLoading && (
                                <div className="progress">
                                    <IonProgressBar type="indeterminate" />
                                    <p>Chargement ...</p>
                                </div>
                            )}

                            {!isLoading && photo && (
                                <IonImg src={photo} />
                            )}

                            {!isLoading && (
                                <IonButton onClick={takePicture} expand="full" className="btn-add">Ajouter une photo/vidéo</IonButton>
                            )}

                            <h1>Solution à mettre en œuvre (pour 1 à 3 actions)</h1>
                            <IonLabel>Action n°1</IonLabel>
                            <IonItem lines='none'>
                                <IonTextarea
                                    placeholder="Écrire ici"
                                    autoGrow={true}
                                    value={action1}
                                    autocapitalize="on"
                                    rows={4}
                                    cols={20}
                                    required
                                    onIonChange={(e) => setAction1(e.detail.value!)}
                                />
                            </IonItem>

                            <IonLabel>Action n°2</IonLabel>
                            <IonItem lines='none'>
                                <IonTextarea
                                    placeholder="Écrire ici"
                                    autoGrow={true}
                                    value={action2}
                                    autocapitalize="on"
                                    rows={4}
                                    cols={20}
                                    onIonChange={(e) => setAction2(e.detail.value!)}
                                />
                            </IonItem>

                            <IonLabel>Action n°3</IonLabel>
                            <IonItem lines='none'>
                                <IonTextarea
                                    placeholder="Écrire ici"
                                    autoGrow={true}
                                    value={action3}
                                    autocapitalize="on"
                                    rows={4}
                                    cols={20}
                                    onIonChange={(e) => setAction3(e.detail.value!)}
                                />
                            </IonItem>
                            <IonButton className='hexis-btn' expand="full" onClick={sendStatement} disabled={registerButtonIsDisabled}>Envoyer</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage >
    );
};

export default Statement;