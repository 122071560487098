import React, { useEffect, useRef, useState } from 'react';
import {
  IonContent,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSlide, IonSlides,
  IonImg, IonItem, IonLabel, IonAvatar
} from '@ionic/react';
import './Gallery.scss';
import { Album, Picture } from "../../models/Galerie";
import { RefresherEventDetail } from '@ionic/core/components';
import Breadcrumb from "../../components/Breadcrumb";
import GalleryTools from "./GalleryTools";
import { ContentState } from "../../state/general/content.reducer";
import { useStateValue } from "../../state/state";
import { getGalleryData } from "../../services/gallery.service";
import Menu from '../../components/Menu';
import MainTabs from '../../components/MainTabs';
import { chevronForwardOutline, chevronBackOutline } from 'ionicons/icons';

const slideOpts = {
  initialSlide: 1,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
};

const Gallery: React.FC = () => {
  const [{ contentState }, dispatch]: [{ contentState: ContentState }, Function] = useStateValue();
  const [selectedAlbums, setSelectedAlbums] = useState({} as Array<number>);
  // const [selectedTags, setSelectedTags] = useState({} as Array<string>);

  const [popupSlides, setPopupSlides] = useState({} as Array<Picture> | undefined);
  const [slidePictures, setSlidePictures] = useState({} as Array<Picture> | undefined);
  const [popupSlideOpts, setPopupSlideOpts] = useState({ initialSlide: 2 });
  const [popupSlideshowOpen, setPopupSlideshowOpen] = useState(false);
  const [popupSlideshowVideo, setPopupSlideshowVideo] = useState('');

  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const popupSlidesRef = useRef<HTMLIonSlidesElement>(null);

  const tagsFilter: string[] = ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4'];

  useEffect(() => {
    const initGallery = async () => {
      await getGalleryData(dispatch).then((content) => {
        filterPictures(content);
      });
    };
    initGallery();
  }, []);

  useEffect(() => {
    filterPictures(contentState.galleryData);
  }, [selectedAlbums]);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getGalleryData(dispatch).then((content) => {
      filterPictures(content);
      event.detail.complete();
    });
  }

  // const choiceTags = (tag: string) => {
  // const exist: string | undefined = selectedTags.find(value => value === tag);
  // if (!exist) {
  //   setSelectedTags(selectedTags.concat(tag));
  // } else {
  //   let newArr = selectedTags.filter(function (obj) {
  //     return obj !== tag;
  //   });
  //   setSelectedTags(newArr);
  // }
  // };

  const choiceAlbum = (album: number) => {
    let exist: number | undefined = undefined;
    if (selectedAlbums.length) {
      exist = selectedAlbums.find(value => value === album);
    }

    if (!exist) {
      if (selectedAlbums.length) {
        setSelectedAlbums(selectedAlbums.concat(album));
      } else {
        setSelectedAlbums([album]);
      }
    } else {
      let newArr = selectedAlbums.filter(function (obj) {
        return obj !== album;
      });
      setSelectedAlbums(newArr);
    }
  };

  // const filterTags = (pictures: Picture[]) => {
  // let filteredPictures: Picture[] = [];
  // for (const picture of pictures) {
  //   const exist: string | undefined = selectedTags.find(value => value == picture.tag);
  //   if(exist) {
  //     filteredPictures.push(picture);
  //   }
  // }
  //
  // return filteredPictures;
  // };

  const filter = () => {
    let filteredData: Album[] = [];
    if (selectedAlbums?.length) {
      for (const album of contentState.galleryData) {
        const exist: number | undefined = selectedAlbums.find(value => value == album.id);
        if (exist) {
          filteredData.push(album);
        }
      }
    } else {
      filteredData = contentState.galleryData;
    }

    // if(selectedTags.length > 1) {
    //   for (let data of filteredData) {
    //     data.pictures = filterTags(featuredPictures);
    //   }
    // }

    return filteredData;
  };

  const filterPictures = (albums: Album[]) => {
    let exist: any;
    let filteredPictures: Picture[] = [];
    if (selectedAlbums.length) {
      for (const album of albums) {
        exist = selectedAlbums.find(value => value == album.id);
        if (exist) {
          if (album.pictures) {
            for (const picture of album.pictures) {
              filteredPictures.push(picture);
            }
          }
        }
      }
    } else {
      for (const album of albums) {
        if (album.pictures) {
          for (const picture of album.pictures) {
            filteredPictures.push(picture);
          }
        }
      }
    }
    setSlidePictures(filteredPictures);
  };

  const albums = filter();

  const openPopupSlideshow = (slideId: number, album: string | number) => {
    if (album === 'slider') {
      setPopupSlides(contentState.albumSlider.pictures);
    } else {
      let popupSlidePictures: Picture[] = [];
      // if(contentState.galleryData[album as number]?.pictures) {
      //     for(const picture of contentState.galleryData[album as number]?.pictures) {
      //         const exist = slidePictures?.find(p => p.id == picture.id);
      //         if(exist) {
      //             popupSlidePictures.push(picture);
      //         }
      //     }
      // }
      setPopupSlides(popupSlidePictures);
    }

    setTimeout(() => {
      setPopupSlideshowOpen(true);
      setPopupSlideOpts({ initialSlide: slideId });
    }, 100);
  };

  const closePopupSlideshow = () => {
    setPopupSlideshowOpen(false);
  };

  const contentSlideshow = (url: string) => {
    if (popupSlideshowVideo !== 'video') {
      return (<IonImg src={url} />);
    }
    else {
      return (
        <video controls>
          <source src={url} type="video/mp4" />
        </video>
      );
    }
  }

  const popupSlideshow = () => {
    if (popupSlideshowOpen) {
      return (
        <div id="popup-slideshow" className={popupSlideshowVideo}>
          <div className="blur" onClick={() => closePopupSlideshow()}>
            <IonIcon className="popup-close-icon" src="/assets/icon/error.svg" />
          </div>
          <div className="swiper">
            <a onClick={() => popupSlidesRef.current?.slidePrev()} className="slide-prev"><IonIcon icon={chevronBackOutline} /></a>
            <IonSlides pager={true} options={popupSlideOpts} ref={popupSlidesRef} key={popupSlides?.length}>
              {popupSlides?.length ? popupSlides.map((slidePicture: Picture) => (
                <IonSlide key={slidePicture.id}>
                  {contentSlideshow(slidePicture.url)}
                  <div className="picture-infos background">
                    <div className="picture-txt-wrapper">
                      <p className="picture-title">{slidePicture.title}</p>
                      <p className="picture-author">{slidePicture.author}</p>
                    </div>
                    <div className="picture-date">{slidePicture.date}</div>
                  </div>
                </IonSlide>
              )) : null}
            </IonSlides>
            <a onClick={() => popupSlidesRef.current?.slideNext()} className="slide-next"><IonIcon icon={chevronForwardOutline} /></a>
          </div>
        </div>
      );
    }
  };

  return (
    <IonPage>
      <Menu />
      <IonContent id="gallery">
        <Breadcrumb title="GALERIE" component={<GalleryTools choiceAlbum={choiceAlbum} albumsFilter={contentState.galleryData} selectedAlbums={selectedAlbums} tagsFilter={tagsFilter} />} />
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>

        <h1>{contentState.albumSlider?.title}</h1>
        {contentState.albumSlider?.description ? <div dangerouslySetInnerHTML={{ __html: contentState.albumSlider.description }} /> : null}
        <div className="swiper">
          <a onClick={() => slidesRef.current?.slidePrev()} className="slide-prev"><IonIcon icon={chevronBackOutline} /></a>
          <IonSlides pager={true} options={slideOpts} ref={slidesRef} key={contentState?.albumSlider?.pictures?.length}>
            {contentState?.albumSlider?.pictures?.length ? contentState.albumSlider.pictures.map((slidePicture: Picture, index) => (
              <IonSlide key={slidePicture.id}>
                <IonImg src={slidePicture.url} onClick={() => openPopupSlideshow(index, 'slider')} />
                <div className="picture-infos">
                  <div className="picture-txt-wrapper">
                    <p className="picture-title">{slidePicture.title}</p>
                    <p className="picture-author">{slidePicture.author}</p>
                  </div>
                  <div className="picture-date">{contentState?.albumSlider?.created}</div>
                </div>
              </IonSlide>
            )) : null}
          </IonSlides>
          <a onClick={() => slidesRef.current?.slideNext()} className="slide-next"><IonIcon icon={chevronForwardOutline} /></a>
        </div>

        {albums?.map((album: Album, i) => (
          <IonItem hidden={album?.pictures?.length === 0} key={album.id} detail={true} routerLink={"/galerie/" + album.id}>
            <IonAvatar slot="start">
              <img src={album?.pictures?.[0]?.url} />
            </IonAvatar>
            <IonLabel>
              <h3>{album.title}</h3>
              {album?.description ? <div dangerouslySetInnerHTML={{ __html: album.description }} /> : null}
            </IonLabel>
          </IonItem>
        ))}
        {popupSlideshow()}
      </IonContent>
      <MainTabs />
    </IonPage>
  );
};

export default Gallery;
