import React, { createContext, useContext, useReducer } from "react";
import { userReducer, UserState } from "./user/user.reducer";
import { registerUserFormReducer, registerUserFormInitialState } from "./user/registerUserForm.reducer";
import { RegisterUserForm } from "../models/RegisterUserForm";
import { DisplayState, displayReducer, displayStateInitialState } from "./general/display.reducer";
import { ContentState, contentReducer, contentStateInitialState } from "./general/content.reducer";

// Custom global State: 
// Doc: https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c

export interface State {
  userState: UserState
  registerUserFormState: RegisterUserForm
  displayState: DisplayState
  contentState: ContentState
}

const initialState: any = {
  userState: {},
  registerUserFormState: registerUserFormInitialState,
  displayState: { toastData: displayStateInitialState },
  contentState: contentStateInitialState,
};

export const StateContext = createContext(initialState as any);

const mainReducer = ({ userState, registerUserFormState, displayState, contentState }: any, action: any) =>({
  userState: userReducer(userState, action),
  registerUserFormState: registerUserFormReducer(registerUserFormState, action),
  displayState: displayReducer(displayState, action),
  contentState: contentReducer(contentState, action),
});

export const StateProvider: React.FC<any> = ({children}) => (
  <StateContext.Provider value={useReducer(mainReducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);