import React from 'react';
import { RouteComponentProps } from "react-router";
import { IonContent, IonPage, IonLabel, IonText } from '@ionic/react';
import Breadcrumb from "../../components/Breadcrumb";
import { ContentState } from "../../state/general/content.reducer";
import { useStateValue } from "../../state/state";
import { FlashInfo } from "../../models/FlashInfo";
import Menu from '../../components/Menu';

const NodeFlashInfos: React.FC<RouteComponentProps<any>> = (props) => {
    const flashId = props.match.params && props.match.params.id;
    const [{contentState}, dispatch] : [{contentState: ContentState}, Function] = useStateValue();
    const currentMenu = contentState.menuLinkData.find(c => c.url === '/flashinfos');
    const flash: FlashInfo | undefined = contentState.flashInfosData.find(c => c.id === flashId);

    return (
      <IonPage>
        <Menu />
        <Breadcrumb title={flash?.title.toUpperCase()} icon={currentMenu?.icon} />
        <IonContent id="nodeflashinfos" className="ion-padding">

          <IonLabel>
            <p>{flash?.created}</p>
          </IonLabel>
          <IonText>{flash?.body}</IonText>

        </IonContent>
      </IonPage>
    );
};

export default NodeFlashInfos;
