import React from 'react';
import {
    IonIcon,
    IonMenu,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonHeader,
    IonToolbar,
    IonButton,
    IonText
} from "@ionic/react";
import { menuController } from '@ionic/core/components';
import { Album } from "../../models/Galerie";
import { checkboxSharp, closeSharp } from 'ionicons/icons';

interface GalleryToolsProps {
    tagsFilter: string[]
    choiceTags?: Function
    choiceAlbum: Function
    albumsFilter: Album[]
    selectedTags?: string[]
    selectedAlbums: number[]
}

const GalleryTools: React.FC<GalleryToolsProps> = (props) => {

    const menuGalleryOpenClose = () => {
        menuController.enable(true, 'galleryFilter');
        menuController.toggle('galleryFilter');
    };

    return (
        <div>
            <IonMenu id="gallery" menuId="galleryFilter" contentId="gallery" type="overlay" side="end" className="sub-menu">
                <IonContent>
                    <IonHeader className='ion-no-border padd-t'>
                        <IonToolbar>
                            <IonText>PAR ALBUM</IonText>
                            <IonButton slot='end' onClick={() => { menuGalleryOpenClose() }} expand="full" fill='clear' >
                                <IonIcon size='large' slot='end' icon={closeSharp} />
                            </IonButton>
                        </IonToolbar>
                    </IonHeader>
                    <IonList id='gallery-filter-menu'>
                        {props.albumsFilter.map((albumFilter, index) => {
                            const selected: number | undefined = props.selectedAlbums.length ? props.selectedAlbums.find(c => c === albumFilter.id) : 0;
                            return (
                                <IonItem onClick={() => props.choiceAlbum(albumFilter.id)} key={index} className={selected ? 'selected' : ''} lines={index === props.albumsFilter.length - 1 ? 'none' : 'full'}>
                                    <IonIcon slot="start" src={checkboxSharp} className={selected ? '' : 'opacity0'} />
                                    <IonLabel text-wrap>{albumFilter.title}</IonLabel>
                                </IonItem>
                            );
                        })}
                    </IonList>
                    {/*<IonList>*/}
                    {/*    <IonListHeader>Par Tag :</IonListHeader>*/}
                    {/*    {props.tagsFilter?.map((tagFilter: string, index) => {*/}
                    {/*        const selected: string | undefined = props.selectedTags.length ? props.selectedTags.find(value => value === tagFilter) : undefined;*/}
                    {/*        return (*/}
                    {/*            <IonItem onClick={() => props.choiceTags(tagFilter)} key={index} className={selected ? 'selected' : ''}>*/}
                    {/*                <IonIcon slot="start" src='/assets/icon/success.svg' className={selected ? '' : 'opacity0'} />*/}
                    {/*                <IonLabel>{tagFilter}</IonLabel>*/}
                    {/*            </IonItem>*/}
                    {/*        );*/}
                    {/*    })}*/}
                    {/*</IonList>*/}
                </IonContent>
            </IonMenu>
        </div>
    );
};

export default GalleryTools;
