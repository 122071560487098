import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  isPlatform,
  IonPage,
  setupIonicReact} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Blog from './pages/blog/Blog';

import { SplashScreen } from '@capacitor/splash-screen';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/hexis.scss';

import { StateProvider } from './state/state';
import Toast from './components/Toast';
import ArticleDetail from "./pages/blog/ArticleDetail";
import Gallery from "./pages/gallery/Gallery";
import GalleryItem from "./pages/gallery/GalleryItem";
import IdeaBox from "./pages/idea-box/IdeaBox";
import Login from "./pages/account/Login";
import ForgotPassword from "./pages/account/ForgotPassword";
import Splash from "./pages/account/Splash";
import Register from "./pages/account/Register";
import Account from "./pages/account/Account";
import Error from "./pages/success-error/Error";
import Success from "./pages/success-error/Success";
import BasicPage from "./pages/basic-page/BasicPage";
import SummaryPage from "./pages/summary-page/SummaryPage";
import MessageList from "./pages/message/MessageList";
import SocialCommittee from "./pages/social-committee/SocialCommittee";
import Composition from "./pages/social-committee/Composition";
import Agenda from "./pages/agenda/Agenda";
import FlashInfos from "./pages/flashinfos/FlashInfos";
import NodeFlashInfos from "./pages/flashinfos/NodeFlashInfos";

import { getJwtValue } from './services/base-http.service';
import Menu from "./components/Menu";
import AddPhoto from "./pages/gallery/AddPhoto";
import AgendaNote from "./pages/agenda/AgendaNote";
import LoadingData from "./components/LoadingData";
import MainTabs from './components/MainTabs';
import Statement from './pages/statement/Statement';

setupIonicReact({
  // mode: 'md'
});

// const { SplashScreen } = Plugins;

const App: React.FC = () => {

  if (isPlatform('cordova')) {
    SplashScreen.hide();
  }

  const isLoggedIn = getJwtValue();

  return (
    <StateProvider>
      <IonApp>
        <IonReactRouter>
          <IonPage>
            <Menu />
            <Toast />
            <LoadingData />

            <IonRouterOutlet id="main">

              <Route exact path="/splash" component={Splash} />
              <Route exact path="/connexion" component={Login} />
              <Route exact path="/passe-oublie" component={ForgotPassword} />
              <Route exact path="/inscription" component={Register} />

              <Route exact path="/blog" component={Blog} />
              <Route exact path="/mon-compte" component={Account} />
              <Route exact path="/article/:id" component={ArticleDetail} />
              <Route exact path="/sommaire/:id" component={SummaryPage} />
              <Route exact path="/declarer" component={Statement} />
              <Route exact path="/agenda" component={Agenda} />
              <Route exact path="/agenda/:id" component={AgendaNote} />
              <Route exact path="/messages" component={MessageList} />
              <Route exact path="/boite-idee" component={IdeaBox} />
              <Route exact path="/galerie" component={Gallery} />
              <Route exact path="/galerie/:id" component={GalleryItem} />
              <Route exact path="/ajouter-photo" component={AddPhoto} />
              <Route exact path="/comite-social" component={SocialCommittee} />
              <Route exact path="/composition" component={Composition} />
              <Route exact path="/page/:id" component={BasicPage} />
              <Route exact path="/flashinfos" component={FlashInfos} />
              <Route exact path="/flashinfos/:id" component={NodeFlashInfos} />
              <Route exact path="/success" component={Success} />
              <Route exact path="/error" component={Error} />

              <Route path="/" render={() => {
                const redirectRoute = isLoggedIn ? <Redirect to="/blog" /> : <Redirect to="/splash" />;
                return redirectRoute
              }} exact={true} />

            </IonRouterOutlet>
            <MainTabs />

          </IonPage>
        </IonReactRouter>
      </IonApp>
    </StateProvider >
  );
};

export default React.memo(App);
