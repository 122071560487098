import React from 'react';
import './NavigationPage.scss';
import { IonButton, IonCol, IonIcon, IonThumbnail } from "@ionic/react";
import { Link } from "react-router-dom";
import { MenuLink } from "../models/Menu";

interface NavigationProps {
    navigationElement: MenuLink;
}

const NavigationPage: React.FC<NavigationProps> = ({ navigationElement }) => {
    // const showSubName: Function = () => {
    //     if (navigationElement.subName) {
    //         return <p>{navigationElement.subName}</p>
    //     }
    // };

    return (
        <IonCol size="6">
            <Link to={(navigationElement.url) ? navigationElement.url : '#'}>
                {navigationElement.icon ?
                    <IonThumbnail>
                        <img src={navigationElement.icon} alt={navigationElement.title} />
                    </IonThumbnail>
                    :
                    null}
                {/*<p className={(navigationElement.subName) ? 'has-subName' : ''}>{navigationElement.title}</p>*/}
                <p>{navigationElement.title}</p>
                {/*{showSubName()}*/}
            </Link>
        </IonCol>
    );
};

export default NavigationPage;
