import { Note } from "../../models/Note";
import { Album } from "../../models/Galerie";
import { MenuLink } from "../../models/Menu";
import { FlashInfo } from "../../models/FlashInfo";
import { PageModel } from "../../models/PageModel";
import { Article, Category } from "../../models/Article";

export enum ContentStateActions {
  StoreBlogData = 'StoreBlogData',
  StorePagesData = 'StorePagesData',
  StoreAgendaData = 'StoreAgendaData',
  StoreGalleryData = 'StoreGalleryData',
  StoreMessageData = 'StoreMessageData',
  StoreInfosPageUrl = 'StoreInfosPageUrl',
  StoreMenuLinkData = 'StoreMenuLinkData',
  StoreAlbumForSlider = 'StoreAlbumForSlider',
  StoreThematiqueData = 'StoreThematiqueData',
  StoreFlashInfosData = 'StoreFlashInfosData',
  StoreContentIsFetching = 'StoreContentIsFetching'
}

export const contentReducer = (state: ContentState, action: any) => {
  switch (action.type) {
    case ContentStateActions.StoreMenuLinkData:
      return {
        ...state,
        menuLinkData: [...action.value]
      };

    case ContentStateActions.StoreAgendaData:
      return {
        ...state,
        agendaData: [...action.value]
      };

    case ContentStateActions.StoreFlashInfosData:
      return {
        ...state,
        flashInfosData: [...action.value]
      };

    case ContentStateActions.StoreBlogData:
      return {
        ...state,
        blogData: [...action.value]
      };

    case ContentStateActions.StoreGalleryData:
      return {
        ...state,
        galleryData: [...action.value]
      };

    case ContentStateActions.StoreMessageData:
      return {
        ...state,
        messageData: [...action.value]
      };

    case ContentStateActions.StoreThematiqueData:
      return {
        ...state,
        thematiqueData: [...action.value]
      };

    case ContentStateActions.StorePagesData:
      return {
        ...state,
        pagesData: state.pagesData.concat(action.value)
      };

    case ContentStateActions.StoreContentIsFetching:
      return {
        ...state,
        contentIsFetching: action.value
      };
    case ContentStateActions.StoreInfosPageUrl:
      return {
        ...state,
        urlInfosPage: action.value
      };
    case ContentStateActions.StoreAlbumForSlider:
      return {
        ...state,
        albumSlider: action.value
      };
    default:
      return state;
  }
};

export const contentStateInitialState = {
  blogData: [],
  pagesData: [],
  agendaData: [],
  flashInfosData: [],
  albumSlider: undefined,
  galleryData: [],
  messageData: [],
  urlInfosPage: '',
  menuLinkData: [],
  thematiqueData: [],
  contentIsFetching: false
};

export interface ContentState {
  blogData: Article[],
  pagesData: PageModel[],
  agendaData: Note[]
  flashInfosData: FlashInfo[]
  albumSlider: Album,
  galleryData: Album[],
  messageData: Article[],
  urlInfosPage: string,
  menuLinkData: MenuLink[]
  thematiqueData: Category[]
  contentIsFetching: boolean
}
