import React, { useEffect } from 'react';
import { IonContent, IonPage, IonRefresher, IonRefresherContent } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core/components';
import Breadcrumb from "../../components/Breadcrumb";
import List from "../../components/List";
import { Article } from "../../models/Article";
import { ContentState } from "../../state/general/content.reducer";
import { useStateValue } from "../../state/state";
import { getMessageData } from "../../services/article.service";
import { BasicParameters } from "../../services/base-http.service";
import { UserState } from "../../state/user/user.reducer";
import Menu from '../../components/Menu';

const MessageList: React.FC = () => {
    const [{ userState, contentState }, dispatch]: [{ userState: UserState, contentState: ContentState }, Function] = useStateValue();
    const parameters: BasicParameters = {};

    useEffect(() => {
        const initMessage = async () => {
            parameters.uid = userState.currentUser.uid;
            await getMessageData(parameters, dispatch);
        };

        initMessage()
    }, [userState.currentUser.nb_messages_unread]);

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        if (!userState || !userState.currentUser || !userState.currentUser.uid) {
            event.detail.complete();
            return;
        }

        parameters.uid = userState.currentUser.uid;
        getMessageData(parameters, dispatch).then(() => {
            event.detail.complete();
        });
    }

    return (
        <IonPage>
            <Menu />
            <Breadcrumb title="MESSAGES" icon="/assets/icon/messages-bottom.svg" />
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent refreshingSpinner="circles">
                    </IonRefresherContent>
                </IonRefresher>
                <div id="list">
                    {contentState.messageData.map((message: Article) => (
                        <List article={message} type="message" key={message.id} />
                    ))}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default MessageList;
