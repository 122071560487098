import React, { useEffect, useState } from 'react';
import {
    IonContent,
    IonPage,
    IonImg,
    IonInput,
    IonButton,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonHeader, IonText, IonCard, IonItem, IonIcon
} from '@ionic/react';

import { RegisterUserFormActions } from '../../state/user/registerUserForm.reducer';
import { RegisterParameters, register } from '../../services/user.service';
import { getToastValue, ToastColor, ToastPosition } from '../../components/Toast';
import { validateEmail, validatePhone } from '../../helpers/formValidation.helper';
import { useStateValue } from "../../state/state";
import { manageLoginError, manageRegisterData } from '../../helpers/login.helper';

import { RegisterUserForm } from '../../models/RegisterUserForm';

import './Account.scss';
import { callOutline, checkmarkCircleOutline, closeCircleOutline, lockClosedOutline, mailOutline, personOutline } from 'ionicons/icons';

const Register: React.FC<any> = ({ history }) => {
    const [{ registerUserFormState }, dispatch]:
        [{ registerUserFormState: RegisterUserForm }, Function] = useStateValue();
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [phoneIsValid, setPhoneIsValid] = useState(false);
    const [registerButtonIsDisabled, setRegisterButtonIsDisabled] = useState(true);

    useEffect(() => {
        if (emailIsValid !== validateEmail(registerUserFormState.email)) setEmailIsValid(!emailIsValid);
        if (phoneIsValid !== validatePhone(registerUserFormState.phone)) setPhoneIsValid(!phoneIsValid);

        const { firstname, lastname, email, password, conf_password, phone } = registerUserFormState;
        const oneInputIsMissing = !firstname || !lastname || !email || !password || !conf_password || !phone;
        const formIsValid = !oneInputIsMissing && password === conf_password && emailIsValid && phoneIsValid;
        setRegisterButtonIsDisabled(!formIsValid)
    }, [registerUserFormState, emailIsValid, phoneIsValid]);

    const renderToast = (message: string, color: ToastColor, position: ToastPosition = "bottom") => {
        dispatch(getToastValue(message, color, position));
    };

    const registerUser = () => {
        const parameters: RegisterParameters = {
            email: registerUserFormState.email,
            phone: registerUserFormState.phone,
            password: registerUserFormState.password,
            lastname: registerUserFormState.lastname,
            firstname: registerUserFormState.firstname,
        };

        register(parameters)
            .then(result => manageRegisterData(result, history, renderToast))
            .catch(error => manageLoginError(error, renderToast))
    };

    return (
        <IonPage id="account">

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="splash" text="" color="medium" className="chevron-sm" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonImg src="/assets/img/logo_hexis.svg" />
                <h1>Créer un compte</h1>
                <IonCard>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={personOutline} />
                        <IonInput
                            placeholder="Nom"
                            onIonChange={e => dispatch({ type: RegisterUserFormActions.StoreLastname, value: e.detail.value })}
                            value={registerUserFormState.lastname}
                            type="text"
                            autocapitalize="on"
                            required>
                        </IonInput>
                    </IonItem>
                </IonCard>
                <IonCard>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={personOutline} />
                        <IonInput
                            placeholder="Prénom"
                            onIonChange={e => dispatch({ type: RegisterUserFormActions.StoreFirstname, value: e.detail.value })}
                            value={registerUserFormState.firstname}
                            type="text"
                            autocapitalize="on"
                            required>
                        </IonInput>
                    </IonItem>
                </IonCard>

                <IonCard>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={mailOutline} />
                        <IonInput
                            placeholder="Adresse email"
                            onIonChange={e => dispatch({ type: RegisterUserFormActions.StoreEmail, value: e.detail.value })}
                            value={registerUserFormState.email}
                            type="email"
                            inputmode="email"
                            autocapitalize="off"
                            required>
                        </IonInput>
                    </IonItem>
                </IonCard>

                <IonCard>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={lockClosedOutline} />
                        <IonInput
                            placeholder="Mot de passe"
                            onIonChange={e => dispatch({ type: RegisterUserFormActions.StorePassword, value: e.detail.value })}
                            value={registerUserFormState.password}
                            type="password"
                            autocapitalize="off"
                            required>
                        </IonInput>
                    </IonItem>
                </IonCard>

                <IonCard>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={lockClosedOutline} />
                        <IonInput
                            placeholder="Confirmation du mot de passe"
                            onIonChange={e => dispatch({ type: RegisterUserFormActions.StoreConf_password, value: e.detail.value })}
                            value={registerUserFormState.conf_password}
                            type="password"
                            autocapitalize="off"
                            required>
                        </IonInput>
                        {registerUserFormState.password && registerUserFormState.conf_password && registerUserFormState.password !== registerUserFormState.conf_password &&
                            <IonIcon color='danger' slot='end' icon={closeCircleOutline} />
                        }
                        {registerUserFormState.password && registerUserFormState.conf_password && registerUserFormState.password === registerUserFormState.conf_password &&
                            <IonIcon color='success' slot='end' icon={checkmarkCircleOutline} />
                        }
                    </IonItem>
                </IonCard>

                {registerUserFormState.password && registerUserFormState.conf_password && registerUserFormState.password !== registerUserFormState.conf_password &&
                    <IonText color="danger">
                        <p className="ion-padding-start">Les mots de passe sont différents</p>
                    </IonText>
                }

                <IonCard>
                    <IonItem lines='none'>
                        <IonIcon color='medium' slot='start' icon={callOutline} />
                        <IonInput
                            placeholder="Numéro de téléphone"
                            onIonChange={e => dispatch({ type: RegisterUserFormActions.StorePhone, value: e.detail.value })}
                            value={registerUserFormState.phone}
                            type="tel"
                            inputmode="tel"
                            required>
                        </IonInput>
                    </IonItem>
                </IonCard>

                <IonButton className='hexis-btn' expand="full" onClick={registerUser} disabled={registerButtonIsDisabled}>Création</IonButton>
            </IonContent>
        </IonPage>
    );
};

export default Register;
