import React, { useEffect } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Article } from '../../models/Article';
import '../Pages.scss';
import Breadcrumb from "../../components/Breadcrumb";
import { RouteComponentProps } from "react-router";
import { ContentState } from "../../state/general/content.reducer";
import { useStateValue } from "../../state/state";
import { UserState } from "../../state/user/user.reducer";
import { BasicParameters } from "../../services/base-http.service";
import { readArticle, storeArticleData } from "../../services/article.service";
import { PhotoViewer } from '@ionic-native/photo-viewer';
import ReactPlayer from 'react-player';
import Menu from '../../components/Menu';

const ArticleDetail: React.FC<RouteComponentProps<any>> = (props) => {
  const blogId = props.match.params && props.match.params.id;
  const [{ contentState, userState }, dispatch]: [{ contentState: ContentState, userState: UserState }, Function] = useStateValue();

  const blog: Article | undefined = contentState.blogData.find(c => c.id === blogId);
  useEffect(() => {
    if (!blog?.read) {
      read();
    }
  }, [blogId]);

  const read = async () => {
    const parameters: BasicParameters = {
      uid: userState.currentUser.uid,
      nid: blogId
    };

    await readArticle(parameters).then((result) => {
      if (result && result.is_read) {
        let content: Article[] = [];
        for (const b of contentState.blogData) {
          if (b.id === blogId) {
            b.read = result.is_read;
          }
          content.push(b);
        }
        storeArticleData({ blog: content }, dispatch);
      }
    }).catch((error => {
      console.log('error', error);
    }));
  };

  const handlePhotoViewer = (target: any) => {
    if (target.src) {
      PhotoViewer.show(target.src);
    }
  }

  useEffect(() => {
    const tag = document.getElementById('page');
    if (!tag) {
      return;
    }
    const imgs = tag.getElementsByTagName('img');
    for (var i = imgs.length - 1; i >= 0; i--) {
      imgs[i].addEventListener('click', function (e) {
        handlePhotoViewer(e?.target);
      });
    }
  }, [blog?.description]);

  return (
    <IonPage>
      <Menu />
      <Breadcrumb title={blog?.category.nom.toUpperCase()} icon={blog?.category.icon} />
      <IonContent>

        {blog?.video ?
          <ReactPlayer url={blog?.video} width={"100%"} controls={true} light={blog?.image_detail?.url} playing={blog?.image_detail?.url ? true : false} />
          :
          blog?.image_detail?.url && <div style={{ backgroundImage: 'url(' + blog?.image_detail?.url + ')' }} className='img-banner' />
        }

        <div id="page">
          <h1>{blog?.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: blog?.description }} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ArticleDetail;
