import { User } from "../../models/User";

export enum UserActions {
    StoreUser = 'StoreUser',
    StoreUnreadImportantMessage = 'StoreUnreadImportantMessage',
}

export const userReducer = (state: UserState, action: any) => {
  switch (action.type) {
    case UserActions.StoreUser:
      return {
        ...state,
        currentUser: action.value,        
      };

    case UserActions.StoreUnreadImportantMessage:
      return {
        ...state,
        unreadImportantMessage: action.value,
      };

    default:
      return state;
  }
};

export interface UserState {
  currentUser: User,
  unreadImportantMessage: number,
}