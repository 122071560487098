import React from 'react';
import {
    IonContent,
    IonPage,
    IonImg,
    IonButton
} from '@ionic/react';
import './Account.scss';

const Splash: React.FC = () => {
    return (
        <IonPage id="account">
            <IonContent className="bg-gradient" scroll-y='false'>
                <IonImg src="/assets/img/logo_hexis.svg" className="logo" />
                <IonButton className='hexis-btn' expand="full" routerLink="/connexion">Se connecter</IonButton>
                <IonButton expand="full" fill="outline" routerLink="/inscription">Créer un compte</IonButton>
            </IonContent>
        </IonPage>
    );
};

export default Splash;
