import React from 'react'

export function triggerHiddenElement(elementId: string) {
  let element: HTMLElement = document.getElementById(elementId) as HTMLElement;
  element.click();
}

export const toBase64 = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const getHiddenInput = (inputId: string, callBackOnChange: Function) => { // callBackOnChange needs to have the file as a parameter
  return (
    <input id={inputId} type="file" onChange={(e: any) => {callBackOnChange(e.target.files[0])}} hidden style={{opacity: 0, width: "1px", height: "1px"}}/>
  )
};
