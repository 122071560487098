import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonRefresher, IonRefresherContent } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core/components';
import Breadcrumb from "../../components/Breadcrumb";
import List from "../../components/List";
import { BasicParameters, getJwtValue, getUidFromJwt, removeJwtHeader } from "../../services/base-http.service";
import { RouteComponentProps } from "react-router";
import { useStateValue } from "../../state/state";
import { User } from "../../models/User";
import { Article } from "../../models/Article";
import { getUserProfile, setToken } from "../../services/user.service";
import { getStaticData } from "../../services/content.service";
import { UserActions, UserState } from "../../state/user/user.reducer";
import { ContentState } from "../../state/general/content.reducer";
import { getBlogData } from "../../services/article.service";
import BlogTools from "./BlogTools";
import { getToastValue } from "../../components/Toast";
import { PushNotifications, PushNotificationSchema, ActionPerformed, Token } from '@capacitor/push-notifications';
import Menu from '../../components/Menu';

const Blogs: React.FC<RouteComponentProps> = (props) => {
    const [{ userState, contentState }, dispatch]: [{ userState: UserState, contentState: ContentState }, Function] = useStateValue();
    const [selectedCategories, setSelectedCategories] = useState([0]);
    const currentMenu = contentState.menuLinkData.find(c => c.url.includes('/blog'));

    useEffect(() => {
        init()
    }, []);

    useEffect(() => {

        if (userState?.currentUser?.uid) {

            // Remove all notifications (badges)
            PushNotifications.removeAllDeliveredNotifications();

            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
                if (notification && notification.title) {
                    dispatch(getToastValue(notification?.title));
                    // TODO, find a way to handle redirect on toast tap (no onclick event triggered on IonToast)
                }
            });

            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
                if (notification.notification.data.type && notification.notification.data.id) {
                    props.history.replace('/' + notification.notification.data.type + '/' + notification.notification.data.id);
                }
            });

            // On succcess, we should be able to receive notifications
            PushNotifications.addListener('registration', (token: Token) => {
                // Call WS to save token server side
                setToken(token.value, userState.currentUser.uid);
            });

            PushNotifications.requestPermissions().then(result => {
                if (result.receive) {
                    // Register with Apple / Google to receive push via APNS/FCM
                    PushNotifications.register();
                }
            });
        }

    }, [userState && userState.currentUser && userState.currentUser.uid]);

    const init = async () => {
        if (!userState || !userState.currentUser) {
            const jwt = await getJwtValue();
            if (!jwt) return goToLogin();

            const userId = getUidFromJwt(jwt);
            if (!userId) return goToLogin();

            const user: User | null = await getUserProfile(userId);
            if (!user || !user.uid) return goToLogin();

            const parameters: BasicParameters = {
                uid: user.uid,
            };

            dispatch({ type: UserActions.StoreUser, value: user })

            await getStaticData(parameters, dispatch);
            await getBlogData(parameters, dispatch);
        } else {
            const parameters: BasicParameters = {
                uid: userState.currentUser.uid,
            };

            if (!contentState.menuLinkData.length) {
                await getStaticData(parameters, dispatch);
            }

            await getBlogData(parameters, dispatch);
        }
    };

    const goToLogin = () => {
        removeJwtHeader();
        props.history.replace('/login')
    };

    const choiceCategory = (category: number) => {
        const exist: number | undefined = selectedCategories.find(value => value === category);

        if (!exist) {
            setSelectedCategories(selectedCategories.concat(category));
        } else {
            let newArr = selectedCategories.filter(function (obj) {
                return obj !== category;
            });
            setSelectedCategories(newArr);
        }
    };

    const resetChoices = () => {
        setSelectedCategories([0]);
    };

    const filter = () => {
        let filteredData: Article[] = [];
        if (selectedCategories.length > 1) {
            for (const blog of contentState.blogData) {
                const exist: number | undefined = selectedCategories.find(value => value == blog.category.id);
                if (exist) {
                    filteredData.push(blog)
                }
            }
        } else {
            filteredData = contentState.blogData;
        }

        return filteredData;
    };

    const blogs = filter();

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        const parameters: BasicParameters = {
            uid: userState.currentUser.uid,
        };

        getBlogData(parameters, dispatch).then(() => {
            event.detail.complete();
        });
    }

    return (
        <IonPage>
            <Menu />
            <Breadcrumb title={currentMenu?.title.toUpperCase()} icon={currentMenu?.icon} component={<BlogTools resetChoices={resetChoices} choiceCategory={choiceCategory} categories={contentState.thematiqueData} selectedCategories={selectedCategories} />} />
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent refreshingSpinner="circles"></IonRefresherContent>
                </IonRefresher>
                <div id="list">
                    {blogs.map((blog: Article) => (
                        <List article={blog} type="blog" key={blog.id} />
                    ))}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Blogs;
