import React, { useEffect, useState } from 'react';
import { useId } from "react-id-generator";
import {
  IonContent, IonPage, IonInput, IonButton, IonLabel, IonItem,
  IonSelect, IonSelectOption, IonCheckbox, IonProgressBar, IonImg, IonCard
} from '@ionic/react';
import './AddPhoto.scss';
import { isPlatform } from "@ionic/react";
import Breadcrumb from "../../components/Breadcrumb";
import { ContentStateActions } from "../../state/general/content.reducer";
import { useStateValue } from "../../state/state";
import { addPhotoParameters, ajoutPhoto } from "../../services/gallery.service";
import { UserActions, UserState } from "../../state/user/user.reducer";
import { getHiddenInput, toBase64 } from "../../helpers/upload.helper";
import { getBase64FromGalleryOrCamera } from "../../helpers/photo.helper";
import { getBasicToastError, getToastValue } from "../../components/Toast";
import { ContentState } from "../../state/general/content.reducer";
import { Album } from "../../models/Galerie";
import { useHistory } from "react-router";
import { User } from "../../models/User";
import { mapUser } from "../../services/user.service";
import Menu from '../../components/Menu';

const AddPhoto: React.FC = () => {
  const [{ userState, contentState }, dispatch]: [{ userState: UserState, contentState: ContentState }, Function] = useStateValue();
  const history = useHistory();

  const [htmlId] = useId();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [photo, setPhoto]: any = useState('');
  const [consent, setConsent] = useState(false);
  const [albumPhoto, setAlbumPhoto] = useState(0);
  const [titrePhoto, setTitrePhoto] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [sendPhotoButtonIsDisabled, setSendPhotoButtonIsDisabled] = useState(true);

  useEffect(() => {
    const oneInputIsMissing = albumPhoto === 0 || titrePhoto === '' || photo === '' || consent === false;
    setSendPhotoButtonIsDisabled(oneInputIsMissing)
  }, [albumPhoto, titrePhoto, photo, consent]);

  useEffect(() => {
    if (!isPlatform('capacitor') && typeof(photo) == "object") {
      toBase64(photo).then((resp: any) => {
        setPhoto(resp);
      });
    }
  }, [photo]);

  const takePicture = async () => {
    setIsLoading(true);
    const base64: any = await getBase64FromGalleryOrCamera(false, htmlId)

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    if (!base64 && isPlatform('capacitor')) return dispatch(getBasicToastError())
    if (base64 === "cancelled") return

    setPhoto(base64);
  }

  const resetForm = () => {
    setPhoto('')
    setConsent(false)
    setTitrePhoto('')
    setAlbumPhoto(0)
    setIsAnonymous(false)
  };

  const addPhoto = () => {
    if (sendPhotoButtonIsDisabled) {
      return true;
    }

    const parameters: addPhotoParameters = {
      uid: userState.currentUser.uid,
      image: photo,
      title: titrePhoto,
      album: albumPhoto,
      is_anonymous: isAnonymous
    };

    ajoutPhoto(parameters, dispatch).then((result: any) => {
      if (result && result.response && result.response === true) {
        const userInfo: User = mapUser(result.user);
        if (userInfo && userInfo.uid) dispatch({ type: UserActions.StoreUser, value: userInfo });
        dispatch(getToastValue("Votre photo a été envoyé"));
        resetForm()
        history.replace('/galerie');
      } else {
        return dispatch(getBasicToastError());
      }
      dispatch({ type: ContentStateActions.StoreContentIsFetching, value: false });
    }).catch(error => {
      console.log('error ajoutPhoto', error);
      dispatch({ type: ContentStateActions.StoreContentIsFetching, value: false });
      return dispatch(getBasicToastError());
    })
  };

  return (
    <IonPage>
      <Menu />
      <Breadcrumb title="AJOUTER PHOTO" icon="/assets/icon/galerie.svg" />
      <IonContent id="add-photo">
        <form onSubmit={(e) => {
          e.preventDefault()
          addPhoto()
        }}>
          <IonCard>
            <IonSelect value={albumPhoto} placeholder="Sélectionnez l'album" onIonChange={e => setAlbumPhoto(e.detail.value)}>
              {contentState.galleryData?.map((album: Album) => (
                <IonSelectOption key={album.id} value={album.id}>{album.title}</IonSelectOption>
              ))}
            </IonSelect>
          </IonCard>

          <IonCard>
            <IonInput
              placeholder="Titre de la photo"
              onIonChange={e => setTitrePhoto(e.detail.value!)}
              value={titrePhoto}
              type="text"
              autocapitalize="on"
              required />
          </IonCard>

          {isLoading && (
            <div className="progress">
              <IonProgressBar type="indeterminate" />
              <p>Chargement ...</p>
            </div>
          )}

          {!isLoading && photo && (
            <IonImg src={photo} />
          )}

          {!isLoading && (
            <IonButton onClick={takePicture} expand="full" className="btn-add">Ajouter une photo</IonButton>
          )}

          {getHiddenInput(htmlId, setPhoto)}

          <div className="checkbox-center">
            <IonItem lines={"none"}>
              <IonCheckbox checked={consent} onIonChange={e => setConsent(e.detail.checked)} />
              <IonLabel text-wrap>J'autorise l'entreprise Hexis à diffuser les photos que je pourrai soumettre au travers de l'application mobile Hexis, en relation avec les activités de l'usine et des conditions générales d'utilisation et dans le respect du règlement interne</IonLabel>
            </IonItem>
          </div>
          <IonButton type='submit' className='hexis-btn ion-margin-bottom btn-margin' expand="full" disabled={sendPhotoButtonIsDisabled}>Envoyer</IonButton>

        </form>
      </IonContent>
    </IonPage>
  );
};

export default AddPhoto;
