import {postHttp, getHttp, BasicParameters} from './base-http.service'
import {ContentStateActions} from "../state/general/content.reducer";
import {Article, RawArticle} from "../models/Article";
import {RawContent} from "./content.service";
import {User} from "../models/User";
import {mapUser} from "./user.service";
import {UserActions} from "../state/user/user.reducer";
import {getIsoFromTimestamp} from "../helpers/date.helper";

const articleUrl = '/article';

const mapArticle = (type: string, rawArticles?: RawArticle[]): Article[] => {
  const articles: Article[] = [];
  if(rawArticles) {
    for (const rawArticle of rawArticles) {
      try {
        const article: Article = {
          id: rawArticle.nid,
          read: rawArticle.is_read,
          title: rawArticle.title,
          image: rawArticle.field_thumbnail,
          category: rawArticle.field_blog_thematique,
          important: rawArticle.field_blog_important,
          description: rawArticle.body,
          image_detail: rawArticle.field_image,
          video: rawArticle?.field_video?.video,
          date: getIsoFromTimestamp(rawArticle.created),
          descriptionShort: rawArticle.summary,
        };
        articles.push(article)
      } catch (error) {
        console.error('Error in mapArticleData(): ', error)
      }
    }
  }

  let arrayReturn: any = {};
  arrayReturn[type] = articles;

  return arrayReturn
};

export const getBlogData = async (parameters: BasicParameters, dispatch: Function): Promise<any> => {
  try {
    return refreshBlogDataFromApi(parameters, dispatch)
  } catch (error) {
    dispatch && dispatch({type: ContentStateActions.StoreContentIsFetching, value: false})
  }
};

const refreshBlogDataFromApi = async (parameters: BasicParameters, dispatch: Function) => {
  dispatch && dispatch({type: ContentStateActions.StoreContentIsFetching, value: false})
  const rawContent: RawContent = await getHttp<RawContent>(articleUrl + '/get_blog', {params: {uid: parameters.uid}});
  const content = mapArticle('blog', rawContent?.blog);

  const userInfo: User = mapUser(rawContent?.user);
  if(userInfo && userInfo.uid) dispatch({type: UserActions.StoreUser, value: userInfo});

  return storeArticleData(content, dispatch)
};

export const getMessageData = async (parameters: BasicParameters, dispatch: Function): Promise<any> => {
  try {
    return refreshMessageDataFromApi(parameters, dispatch)
  } catch (error) {
    dispatch && dispatch({type: ContentStateActions.StoreContentIsFetching, value: false})
  }
};

const refreshMessageDataFromApi = async (parameters: BasicParameters, dispatch: Function) => {
  dispatch && dispatch({type: ContentStateActions.StoreContentIsFetching, value: true})
  const rawContent: RawContent = await getHttp<RawContent>(articleUrl + '/get_messages', {params: {uid: parameters.uid}});
  const content = mapArticle('message', rawContent?.messages);

  const userInfo: User = mapUser(rawContent?.user);
  if(userInfo && userInfo.uid) dispatch({type: UserActions.StoreUser, value: userInfo});

  return storeArticleData(content, dispatch)
};

export const storeArticleData = async (content: any, dispatch: Function) => {
  if (content?.blog) dispatch({type: ContentStateActions.StoreBlogData, value: content.blog});
  if (content?.message) dispatch({type: ContentStateActions.StoreMessageData, value: content.message});
  dispatch({type: ContentStateActions.StoreContentIsFetching, value: false});

  return content
};

export const readArticle = async (parameters: BasicParameters): Promise<any> => {
  return await postHttp(articleUrl + '/set_is_read', parameters)
};