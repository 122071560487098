import React, { useRef, useState } from 'react';
import { IonCol, IonContent, IonIcon, IonPage, IonRow, IonSlide, IonSlides, IonImg, IonThumbnail } from '@ionic/react';
import { Album, Picture } from "../../models/Galerie";
import Breadcrumb from "../../components/Breadcrumb";
import { ContentState } from "../../state/general/content.reducer";
import { useStateValue } from "../../state/state";
import { RouteComponentProps } from "react-router";
import { chevronForwardOutline, chevronBackOutline } from 'ionicons/icons';

import './Gallery.scss';
import { closeCircleSharp } from 'ionicons/icons';
import Menu from '../../components/Menu';

const GalleryItem: React.FC<RouteComponentProps<any>> = (props) => {
  const [{ contentState }, dispatch]: [{ contentState: ContentState }, Function] = useStateValue();

  const itemId = props.match.params && props.match.params.id;
  const album: Album | undefined = contentState.galleryData.find(c => c.id === itemId);

  const [popupSlides, setPopupSlides] = useState({} as Array<Picture> | undefined);
  const [slidePictures, setSlidePictures] = useState({} as Array<Picture> | undefined);
  const [popupSlideOpts, setPopupSlideOpts] = useState({ initialSlide: 2 });
  const [popupSlideshowOpen, setPopupSlideshowOpen] = useState(false);
  const [popupSlideshowVideo, setPopupSlideshowVideo] = useState('');

  const popupSlidesRef = useRef<HTMLIonSlidesElement>(null);

  const openPopupSlideshow = (slideId: number) => {
    setPopupSlides(album?.pictures);
    setTimeout(() => {
      setPopupSlideshowOpen(true);
      setPopupSlideOpts({ initialSlide: slideId });
    }, 100);
  };

  const closePopupSlideshow = () => {
    setPopupSlideshowOpen(false);
  };

  const contentSlideshow = (url: string) => {
    if (popupSlideshowVideo !== 'video') {
      return (<IonImg src={url} />);
    }
    else {
      return (
        <video controls>
          <source src={url} type="video/mp4" />
        </video>
      );
    }
  }

  const popupSlideshow = () => {
    if (popupSlideshowOpen) {
      return (
        <div id="popup-slideshow" className={popupSlideshowVideo}>
          <div className="blur" onClick={() => closePopupSlideshow()}>
            <IonIcon className="popup-close-icon" color='primary' src={closeCircleSharp} />
          </div>
          <div className="swiper">
            <a onClick={() => popupSlidesRef.current?.slidePrev()} className="slide-prev"><IonIcon icon={chevronBackOutline} /></a>
            <IonSlides pager={true} options={popupSlideOpts} ref={popupSlidesRef} key={popupSlides?.length}>
              {popupSlides?.length ? popupSlides.map((slidePicture: Picture) => (
                <IonSlide key={slidePicture.id}>
                  {contentSlideshow(slidePicture.url)}
                  <div className="picture-infos background">
                    <div className="picture-txt-wrapper">
                      <p className="picture-title">{slidePicture.title}</p>
                      <p className="picture-author">{slidePicture.author}</p>
                    </div>
                    <div className="picture-date">{slidePicture.date}</div>
                  </div>
                </IonSlide>
              )) : null}
            </IonSlides>
            <a onClick={() => popupSlidesRef.current?.slideNext()} className="slide-next"><IonIcon icon={chevronForwardOutline} /></a>
          </div>
        </div>
      );
    }
  };

  return (
    <IonPage>
      <Menu />
      <IonContent id="gallery">
        <Breadcrumb title={album?.title} />
        <div className="album-container" hidden={album?.pictures?.length === 0}>
          <h3></h3>
          <IonRow>
            {album?.pictures ? album.pictures.map((picture: Picture, index) => (
              <IonCol size="6" key={index}>
                <IonThumbnail onClick={() => openPopupSlideshow(index)}>
                  <IonImg src={picture.url} />
                </IonThumbnail>
              </IonCol>
            )) : null}
          </IonRow>
        </div>
        {popupSlideshow()}
      </IonContent>
    </IonPage>
  );
};

export default GalleryItem;
