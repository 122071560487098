import React, { useState, useEffect } from 'react';
import { IonToast } from '@ionic/react';
import { useStateValue } from '../state/state';
import { DisplayStateActions } from '../state/general/display.reducer';
import { close } from 'ionicons/icons';

interface ToastProps {}
export type ToastColor = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | 'grey';
export type ToastPosition = 'top' | 'bottom' | 'middle'

export const getToastValue = (message: string, color: ToastColor = "success", position: ToastPosition = "top", duration = 3000)=> {
  return {
    type: DisplayStateActions.ShowToast,
    value: {message, duration, color, position}
  }
};

export const getBasicToastError = (position: ToastPosition = "top") => {
  return {
    type: DisplayStateActions.ShowToast,
    value: {message: 'Oups... une erreur est survenue', duration: 3000, color: 'danger', position}
  }
};

const Toast: React.FC<ToastProps> = () => {
  const [{displayState}] = useStateValue();
  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const {message, duration, color, position} = displayState.toastData;

  useEffect(() => {
    if (displayState.toastData.message) {
      setIsOpen(true);
    }
  }, [displayState.toastData]);

  return (

    <IonToast
      animated={true}
      isOpen={isOpen}
      message= {message}
      duration={duration}
      color={color ? color : "success"}
      onDidDismiss={() => setIsOpen(false)}
      position={position ? position : "top"}
      buttons={[
        {
          side: 'end',
          icon: close,
          handler: () => {
            return setIsOpen(false)
          }
        }]}
      />
  )
};
export default Toast
