import {getHttp, Id, postHttp} from './base-http.service'
import {RawUser, User} from '../models/User'

export interface addIdeaParameters {
  uid: Id
  photo: string
  message: string
  problem: string
  thematique: string
  is_anonymous: boolean
}

export interface LoginParameters {
  login: string
  password: string
}

export interface RegisterParameters {
  email: string
  phone: string
  password: string
  lastname: string
  firstname: string
}

const userUrl = '/user';

export const setToken = (token: any, userId: Id) => {
  let push_token = localStorage.getItem('push_token');
  if (!push_token || push_token !== token) {
    localStorage.setItem('push_token', token);
    return postHttp(userUrl + '/insert_token_push_notif', {token: token, uid: userId})
  }
}

export const mapUser = (rawUser: RawUser): User => {
  try {
    const uid = rawUser.uid || '';
    const nom = rawUser.lastname || '';
    const email = rawUser.email || '';
    const prenom = rawUser.firstname || '';
    const planning = rawUser.planning || '';
    const telephone = rawUser.phone || '';
    const nb_messages_unread = rawUser.nb_messages_unread || 0;

    return {uid, nom, email, prenom, planning, telephone, nb_messages_unread}
  } catch (error) {
    console.error('mapUser error', error);
    throw error
  }
};

export const getUserProfile = async (userId: Id) => {
  const result = await getHttp<any>(userUrl +'/get_profile', {params: {uid: userId}});
  const rawUser: RawUser = result && result.user ? result.user : null;
  return rawUser ? mapUser(rawUser) : null;
};

export const login = (parameters: LoginParameters) => {
  return postHttp(userUrl + '/login', parameters)
};

export const logout = (data: RawUser) => {
  return postHttp(userUrl + '/logout', data)
};

export const removeAccount = (data: RawUser) => {
  return postHttp(userUrl + '/remove_account', data)
};

export const register = (parameters: RegisterParameters) => {
  return postHttp(userUrl + '/register', parameters)
};

export const saveUserInfos = (data: RawUser) => {
  return postHttp(userUrl + '/infos_save', data)
};

export const ajoutIdee = (data: addIdeaParameters) => {
  return postHttp(userUrl + '/contact', data)
};
