import { getHttp } from './base-http.service'
import { FlashInfo, RawFlashInfo } from "../models/FlashInfo";
import { ContentStateActions } from "../state/general/content.reducer";
import { RawContent } from "./content.service";
import { getDateFromIso, getIsoFromTimestamp, getTimeFromIso } from "../helpers/date.helper";

const endpointUrl = '/flashinfo';

const mapFlashInfos = (rows: RawFlashInfo[] | undefined): FlashInfo[] => {
  const data: FlashInfo[] = [];
  if(!rows) {
    return data;
  }
  for (const row of rows) {
    try {
      const flashinfo: FlashInfo = {
        id: row.nid,
        title: row.title,
        body: row.field_description,
        created: getDateFromIso(getIsoFromTimestamp(row.created as string)) + ' ' + getTimeFromIso(getIsoFromTimestamp(row.created as string)),
      };
      data.push(flashinfo)
    } catch (error) {
      console.error('Error in mapFlashInfos(): ', error)
    }
  }

  return data
};

export const getFlashInfosData = async (dispatch: Function): Promise<any> => {
  try {
    return refreshFlashInfosDataFromApi(dispatch)
  } catch (error) {
    dispatch && dispatch({type: ContentStateActions.StoreContentIsFetching, value: false})
  }
};

const refreshFlashInfosDataFromApi = async (dispatch: Function) => {
  dispatch && dispatch({type: ContentStateActions.StoreContentIsFetching, value: true});

  const rawContent: RawContent = await getHttp<RawContent>(endpointUrl + '/get_flash_infos', {});
  const content = mapFlashInfos(rawContent?.flash_info);

  return storeFlashInfosData(content, dispatch)
};

const storeFlashInfosData = async (content: FlashInfo[], dispatch: Function) => {
  if (content) dispatch({type: ContentStateActions.StoreFlashInfosData, value: content});
  dispatch({type: ContentStateActions.StoreContentIsFetching, value: false});

  return content
};
