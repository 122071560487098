import React from 'react';
import './List.scss';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonThumbnail } from "@ionic/react";
import { Article } from "../models/Article";
import { getDateDayMonthFromIso } from "../helpers/date.helper";

interface ListProps {
    article?: Article;
    type: string;
}

const List: React.FC<ListProps> = ({ article, type }) => {

    let klass = '';
    if (article?.important && type === 'blog') {
        klass += 'important';
    } else if (article?.read && type === 'message') {
        klass += 'read';
    }

    const articleHtml = () => {
        if (article) {
            return (
                <>
                    <IonCard className={klass} routerLink={'/article/' + article.id}>
                        <IonItem lines='none'>
                            <IonThumbnail slot="start">
                                <img src={article?.image?.url} alt={article?.image?.alt} />
                            </IonThumbnail>
                            <IonCardHeader>
                                <IonCardSubtitle>{getDateDayMonthFromIso(article.date)}</IonCardSubtitle>
                                {/* <h2>{article.category.nom}</h2> */}
                                <IonCardSubtitle text-wrap color='primary'>{article.category.nom}</IonCardSubtitle>
                                <IonCardTitle text-wrap>{article.title}</IonCardTitle>
                            </IonCardHeader>
                        </IonItem>
                    </IonCard>
                </>
            )
        }
    };

    return (
        <div>
            {articleHtml()}
        </div>
    );
};

export default List;
