import { IonButton, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonTitle, IonToolbar, } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './Menu.scss';
import { menuController } from '@ionic/core/components';
import { getJwtValue } from '../services/base-http.service';
import Header from "./Header";
import { ContentState } from "../state/general/content.reducer";
import { useStateValue } from "../state/state";
import { UserState } from "../state/user/user.reducer";
import { closeSharp } from 'ionicons/icons';

const Menu: React.FC<RouteComponentProps> = ({ history, location }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [btnMenuClass, setBtnMenuClass] = useState('btn-menu');

    const [{ contentState }]: [{ contentState: ContentState }] = useStateValue();
    const [{ userState }]: [{ userState: UserState }] = useStateValue();

    const [selectedPage, setSelectedPage] = useState('/blog');
    const [customHistory, setCustomHistory] = useState(['/blog']);

    const menuSort = contentState.menuLinkData.filter(c => c.title !== 'News' && c.title !== 'Infos pratiques');

    const jwt = getJwtValue();

    useEffect(() => {
        if (userState?.currentUser?.uid) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, [userState?.currentUser?.uid]);


    // @ts-ignore
    const direction = location.state?.direction;

    if (selectedPage !== location.pathname) {
        if (direction === 'back') {
            customHistory.pop();
            setCustomHistory(customHistory);
        } else if (history.action === 'PUSH') {
            setCustomHistory(customHistory.concat(location.pathname));
        }

        setSelectedPage(location.pathname);
    }

    // const showBackButton = (customHistory.length > 1 && customHistory[customHistory.length - 1] !== '/connexion');
    const showBackButton = location.pathname.includes('/blog') || location.pathname.includes('/messages') || location.pathname.includes('/sommaire') || location.pathname.includes('/mon-compte') || location.pathname.includes('/declarer');

    const menuOpenClose = () => {
        if (menuOpen) {
            menuController.close('mainMenu');
            menuController.swipeGesture(true, 'mainMenu');
        } else {
            menuController.enable(true, 'mainMenu');
            menuController.open('mainMenu');
            menuController.swipeGesture(false, 'mainMenu');
        }

        return;
    };

    // const handleClickOutside = (event: Event) => {
    //     if (event.target === document.getElementById('menu-content')) {
            // console.log('click outside');
            // menuOpenClose();
        // }
    // };

    // useEffect(() => {
    //     document.addEventListener('click', handleClickOutside, true);
    //     return () => {
    //         document.removeEventListener('click', handleClickOutside, true);
    //     };
    // });

    const _handleMenuWillOpen = () => {
        setBtnMenuClass('btn-menu close');
        setMenuOpen(true);
    };

    const _handleMenuWillClose = () => {
        setBtnMenuClass('btn-menu');
        setMenuOpen(false);
    };

    const menuBlogOpenClose = () => {
        menuController.enable(true, 'blogFilter');
        menuController.toggle('blogFilter');
    };

    return (
        <>
            <Header menuOpenClose={menuOpenClose} btnMenuClass={btnMenuClass} showBackButton={showBackButton} />

            <IonMenu id="menu-content" menuId="mainMenu" contentId="main" type="overlay" side="end"
                disabled={!isLoggedIn}
                onIonWillOpen={_handleMenuWillOpen}
                onIonDidOpen={_handleMenuWillOpen}
                onIonDidClose={_handleMenuWillClose}>

                <IonHeader className='ion-no-border'>
                    <IonToolbar>
                        <IonTitle></IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonHeader className='ion-no-border'>
                    <IonToolbar>
                        <IonButton slot='end' onClick={() => { menuBlogOpenClose() }} expand="full" fill='clear' >
                            <IonIcon size='large' slot='end' icon={closeSharp} />
                        </IonButton>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonList id="inbox-list">
                        <IonListHeader>MENU</IonListHeader>
                        {menuSort.map((menuLink, index) => {
                            return (
                                <IonItem key={index} onClick={() => {
                                    menuOpenClose();
                                    if (selectedPage !== menuLink.url) {
                                        history.push(menuLink.url);
                                    }
                                }} className={selectedPage === menuLink.url ? 'selected' : ''} lines="none" detail={false}>
                                    {menuLink?.icon_menu ? <img className="img-ion-icon" slot="start" src={menuLink.icon_menu} /> : null}
                                    <IonLabel text-wrap>{menuLink.title}</IonLabel>
                                </IonItem>
                            );
                        })}

                        {userState?.currentUser?.planning &&
                            <IonItem onClick={() => {
                                window.open(userState.currentUser.planning + "?t=" + jwt, "_blank");
                            }} lines="none" detail={false}>
                                <img className="img-ion-icon" slot="start" src="/assets/icon/calendar.svg" />
                                <IonLabel>Planning</IonLabel>
                            </IonItem>
                        }
                    </IonList>
                </IonContent>
            </IonMenu>
        </>

    );
};

export default withRouter(Menu);
