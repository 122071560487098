import React, { useEffect } from 'react';
import { IonContent, IonPage, IonList, IonItem, IonLabel } from '@ionic/react';
import Breadcrumb from "../../components/Breadcrumb";
import { ContentState } from "../../state/general/content.reducer";
import { useStateValue } from "../../state/state";
import { getFlashInfosData } from "../../services/flashinfos.service";
import { FlashInfo } from "../../models/FlashInfo";
import Menu from '../../components/Menu';

const FlashInfos: React.FC = (props) => {
    const [{contentState}, dispatch] : [{contentState: ContentState}, Function] = useStateValue();
    const currentMenu = contentState.menuLinkData.find(c => c.url === '/flashinfos');

    useEffect(() => {
      const init = async () => {
        await getFlashInfosData(dispatch);
      };
      init();
    }, []);

    return (
      <IonPage>
        <Menu />
        <Breadcrumb title="FLASH INFO" />
        <IonContent id="flashinfos">
          <IonList>

            {contentState?.flashInfosData?.length > 0 && contentState.flashInfosData.map((row: FlashInfo) => (
              <IonItem key={row.id} routerLink={"/flashinfos/"+row.id} detail={true}>
                <IonLabel>
                  <h3><b>{row.title.toUpperCase()}</b></h3>
                  <p>{row.created}</p>
                  <span>{row.body}</span>
                </IonLabel>
              </IonItem>
            ))}

          </IonList>
        </IonContent>
      </IonPage>
    );
};

export default FlashInfos;
