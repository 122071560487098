import React from 'react';
import { useStateValue } from '../state/state';
import { IonLoading } from '@ionic/react';

export interface LoadingDataProps{}
 
const LoadingData: React.FC<LoadingDataProps> = () => {
  const [{contentState}] = useStateValue();
  const displayLoading = !!(contentState && contentState.contentIsFetching);
  return ( 
    <IonLoading
        isOpen={displayLoading}
        message={"Veuillez patienter, initialisation des données en cours..."}
    />
   );
};
 
export default LoadingData;
