import React from 'react';
import {IonContent, IonPage, IonIcon} from '@ionic/react';
import './SucessError.scss';
import {ErrorMessage} from "../../models/SuccessError";

const errorMessage: ErrorMessage = {code:'404', title: 'Oups', text: <p>Désolé ce contenu n'existe pas.</p>};

const Error: React.FC = () => {
    return (
        <IonPage id="error">
            <IonContent>
                <div className="message-wrapper">
                    <h1>
                        <IonIcon className="error-icon" slot="start" src="/assets/icon/error.svg"/>
                        <span>{errorMessage.title} {errorMessage.code}</span>
                    </h1>
                    {errorMessage.text}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Error;
